import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { useMsal } from '@azure/msal-react';
import { msalConfig } from '../auth/authConfig';
import useUserStore from '../store/userStore';
import useSensorStore from '../store/sensorStore';
import { errorHandler } from '../utils/helpers';
import useFetchSensors from './useFetchSensors';
import { UploadResponse, UploadResponseCounters } from '../definitions/sensor';

interface CreateSensorRequest {
  token: string | null;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
  file: any;
  userId: string;
  fetchSensors: () => void;
  updateUploadResponse: (uploadResponse: UploadResponse[]) => void;
  updateUploadResponseCounters: (uploadResponse: UploadResponseCounters) => void;
};

const createSensorRequest = async ({
    token,
    setError,
    setLoading,
    file,
    userId,
    fetchSensors,
    updateUploadResponse,
    updateUploadResponseCounters,
  }: CreateSensorRequest) => {
  setLoading(true);
  updateUploadResponse([]);
  updateUploadResponseCounters({ processed: 0, failed: 0 });
  try {
    const formData = new FormData();
    formData.append('sensorsFile', file);
    const response = await axios.post(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Sensor/Upload`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
        userId: userId,
      },
    });
    if (response.status === 200) {
      const { result, processed, failed } = response.data;
      updateUploadResponseCounters({ processed, failed });
      updateUploadResponse(result);
      NotificationManager.info('File is uploaded. Please check the upload logs.');
    }
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    setLoading(false);
    fetchSensors();
  }
};

interface CreateSensor {
  uploadSensors: (file: any) => void;
  loading: boolean;
  error: Error | null;
};

const useUploadSensors = (): CreateSensor => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { updateUploadResponse, updateUploadResponseCounters } = useSensorStore();
  const { fetchSensors } = useFetchSensors();
  
  const { user } = useUserStore();

  const uploadSensors = (file: any) => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };

    if (!user) return navigate('/sign-in');

    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => createSensorRequest({
        token: response.accessToken,
        setError,
        setLoading,
        userId: user.id,
        file,
        fetchSensors,
        updateUploadResponse,
        updateUploadResponseCounters,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { uploadSensors, loading, error };
};

export default useUploadSensors;

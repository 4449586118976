import { create } from 'zustand';
import {
  SensorStatus,
  SensorUseCase,
  SensorModel,
  PurchaseOrder,
  SensorsListItem,
  InstallationHistory,
  CommentHistory,
  UploadResponse,
  UploadResponseCounters,
} from '../definitions/sensor';

interface SensorStore {
  sensorsList: SensorsListItem[];
  updateSensorsList: (sensorList: SensorsListItem[]) => void;

  sensorStatuses: SensorStatus[] | [];
  updateSensorStatuses: (sensorStatuses: SensorStatus[]) => void;

  installationHistory: InstallationHistory[];
  updateInstallationHistory: (installationHistory: InstallationHistory[]) => void;

  commentHistory: CommentHistory[];
  updateCommentHistory: (commentHistory: CommentHistory[]) => void;

  sensorUseCases: SensorUseCase[] | [];
  updateSensorUseCases: (sensorUseCases: SensorUseCase[]) => void;

  sensorModels: SensorModel[] | [];
  updateSensorModels: (sensorModels: SensorModel[]) => void;

  sensorPurchaseOrders: PurchaseOrder[] | [];
  updatePurchaseOrders: (sensorPurchaseOrders: PurchaseOrder[]) => void;

  editSensorData: SensorsListItem | null;
  updateEditSensorData: (editSensorData: SensorsListItem | null) => void;

  uploadResponse: UploadResponse[];
  updateUploadResponse: (uploadResponse: UploadResponse[]) => void;

  uploadResponseCounters: UploadResponseCounters;
  updateUploadResponseCounters: (uploadResponse: UploadResponseCounters) => void;

  loading: boolean;
  updateLoading: (loading: boolean) => void;
}

const useSensorStore = create<SensorStore>()((set) => ({
  sensorsList: [],
  updateSensorsList: (sensorsList) => set({ sensorsList }),

  installationHistory: [],
  updateInstallationHistory: (installationHistory) => set({ installationHistory }),

  commentHistory: [],
  updateCommentHistory: (commentHistory) => set({ commentHistory }),

  sensorStatuses: [],
  updateSensorStatuses: (sensorStatuses) => set({ sensorStatuses }),

  sensorUseCases: [],
  updateSensorUseCases: (sensorUseCases) => set({ sensorUseCases }),

  sensorModels: [],
  updateSensorModels: (sensorModels) => set({ sensorModels }),

  sensorPurchaseOrders: [],
  updatePurchaseOrders: (sensorPurchaseOrders) => set({ sensorPurchaseOrders }),

  editSensorData: null,
  updateEditSensorData: (editSensorData) => set({ editSensorData }),

  uploadResponse: [],
  updateUploadResponse: (uploadResponse) => set({ uploadResponse }),

  uploadResponseCounters: {
    processed: 0,
    failed: 0,
  },
  updateUploadResponseCounters: (uploadResponseCounters) => set({ uploadResponseCounters }),

  loading: false,
  updateLoading: (loading) => set({ loading }),
}));

export default useSensorStore;
import { NotificationManager } from 'react-notifications';
import moment, { Moment } from 'moment-timezone';

export const objectCleaner = (obj: {}): {} => 
  Object.keys(obj).reduce((acc, curr: string) => {
    const value = obj[curr];
    if (value) {
      acc[curr] = value;
    }
    return acc;
  },
{});

export const errorHandler = (error, setError: (error) => void) => {
  const responseError = error?.response?.data?.title;
  setError(error);
  if (responseError) {
    NotificationManager.error(responseError);
  } else {
    NotificationManager.error(error.message);
  }
};

export const formatDateToCDT = (date: Moment | string) => moment.tz(moment(date), "America/Chicago").format('MMM Do YYYY h:mm a');

import React, { useEffect, useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { NotificationContainer } from 'react-notifications';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ListAltIcon from '@mui/icons-material/ListAlt';
import useFetchUser from '../hooks/useFetchUser';
import useUserStore from '../store/userStore';
import 'react-notifications/lib/notifications.css';

import { styled } from '@mui/material/styles';

import Header from './Header';
import SideBar from './SideBar';

const drawerWidth = 240;

const SIDE_BAR_ITEMS = [
  {
    link: "/",
    text: 'Dashboard',
    icon: <InboxIcon />,
  },
  {
    link: "/picklist",
    text: 'Picklist',
    icon: <ListAltIcon />,
  },
];

const ACTION_SIDE_BAR_ITEMS = [
  {
    text: 'Log out',
    icon: <LogoutIcon />,
    action: 'logout',
  },
];

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const MainLayout = ({ children }) => {
  const { user } = useUserStore();
  const { fetchUser } = useFetchUser();
  const [open, setOpen] = useState<boolean>(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!user) {
      fetchUser();
    }
  }, [user]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header open={open} handleDrawerOpen={handleDrawerOpen} />
      <SideBar items={SIDE_BAR_ITEMS} actionItems={ACTION_SIDE_BAR_ITEMS} open={open} handleDrawerClose={handleDrawerClose} />
      <Main open={true}>
        <DrawerHeader />
        <NotificationContainer />
        {children}
      </Main>
    </Box>
  );
};

export default MainLayout;
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import useVendorStore from '../store/vendorStore';
import { msalConfig } from '../auth/authConfig';
import { errorHandler } from '../utils/helpers';
import { Vendor, Contact } from '../definitions/vendor';

interface FetchUser {
  token: string | null;
  updateVendors: (data: Vendor[]) => void;
  updateAllVendorsContacts: (data: Contact[]) => void;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
};

const fetchData = async ({
    token,
    updateVendors,
    updateAllVendorsContacts,
    setError,
    setLoading,
  }: FetchUser) => {
  try {
    const response = await axios.get(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Vendor`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    const vendors = response.data;
    const vendorContacts = vendors.reduce((acc, curr) => {
      const contacts = curr.contacts.map(contact => ({
        vendorName: curr.name,
        ...contact,
      }));
      acc = [...acc, ...contacts];
      return acc;
    }, []);
    updateVendors(vendors);
    updateAllVendorsContacts(vendorContacts);
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    setLoading(false);
  }
};

interface FetchVendors {
  fetchVendors: () => void;
  loading: boolean;
  error: Error | null;
};

const useFetchVendors = (): FetchVendors => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const { updateVendors, updateAllVendorsContacts } = useVendorStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchVendors = () => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };
    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => fetchData({
        token: response.accessToken,
        updateAllVendorsContacts,
        updateVendors,
        setError,
        setLoading,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { fetchVendors, loading, error };
};

export default useFetchVendors;

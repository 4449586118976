import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

type SelectOption = {
  id: string | number,
  description: string,
}

interface ISelect {
  value?: string | number | null;
  labelId: string;
  id: string;
  label: string;
  options: SelectOption[] | [];
  register: any;
  required?: boolean | string;
  disabled?: boolean;
  error?: boolean;
  onChange?: (e: React.SyntheticEvent) => void;
}

export default function SelectComponent({
  labelId,
  id,
  label,
  options,
  register,
  value = '',
  required = false,
  disabled = false,
  error = false,
  onChange,
}: ISelect) {
  return (
    <FormControl sx={{ minWidth: '100%' }}>
      <InputLabel sx={{ backgroundColor: '#fff', paddingRight: 1 }} id={labelId}>{label}{required && ' *'}</InputLabel>
        <Select
          labelId={labelId}
          id={id}
          value={value || ''}
          label="label"
          error={error}
          disabled={disabled}
          required={required}
          {...register(id, { required, onChange })}
        >
          {!required && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {options.map((item: SelectOption) => (
            <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
          ))}
        </Select>
    </FormControl>
  );
}
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { useMsal } from '@azure/msal-react';
import useFetchSensorModels from './useFetchSensorModels';
import { msalConfig } from '../auth/authConfig';
import { errorHandler } from '../utils/helpers';
import useUserStore from '../store/userStore';

interface DeleteSensorModelRequest {
  id: string | number;
  token: string | null;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
  fetchSensorsModels: () => any;
  userId: string | number;
};

const deleteSensorModelRequest = async ({
    token,
    setError,
    setLoading,
    fetchSensorsModels,
    id,
    userId,
  }: DeleteSensorModelRequest) => {
  try {
    const response = await axios.delete(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Sensor/Models/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
        userId,
      },
    });
    if (response.status === 200) {
      NotificationManager.success('Sensor Model is successfully deleted');
    }
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    fetchSensorsModels();
    setLoading(false);
  }
};

interface DeleteSensorModel {
  deleteSensorModel: (id: string | number) => void;
  loading: boolean;
  error: Error | null;
};

const useDeleteSensorModel = (): DeleteSensorModel => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { fetchSensorsModels } = useFetchSensorModels();
  
  const { user } = useUserStore();

  const deleteSensorModel = (id) => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };

    if (!user) return navigate('/sign-in');

    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => deleteSensorModelRequest({
        id,
        token: response.accessToken,
        setError,
        fetchSensorsModels,
        setLoading,
        userId: user.id,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { deleteSensorModel, loading, error };
};

export default useDeleteSensorModel;

import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import useSensorStore from '../store/sensorStore';
import { errorHandler } from '../utils/helpers';
import { msalConfig } from '../auth/authConfig';

interface FetchData {
  token: string | null;
  updateSensorUseCases: (data: any) => any;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
};

const fetchData = async ({
    token,
    updateSensorUseCases,
    setError,
    setLoading,
  }: FetchData) => {
  try {
    const response = await axios.get(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Sensor/UseCases`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    const useCases = response.data;
    updateSensorUseCases(useCases);
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    setLoading(false);
  }
};

interface SensorUseCases {
  fetchSensorsUseCases: () => void;
  loading: boolean;
  error: Error | null;
};

const useFetchSensorUseCases = (): SensorUseCases => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const { updateSensorUseCases } = useSensorStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchSensorsUseCases = () => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };
    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => fetchData({
        token: response.accessToken,
        updateSensorUseCases,
        setError,
        setLoading,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { fetchSensorsUseCases, loading, error };
};

export default useFetchSensorUseCases;

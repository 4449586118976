import React, { useState, useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { NotificationManager } from 'react-notifications';
import { isDeepEqual } from '@mui/x-data-grid/internals';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Close';
import PlusIcon from '@mui/icons-material/PlusOne';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridColDef, GridRowId, GridRowModes } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { SensorDTO, SensorsListItem } from '../definitions/sensor';
import { Client } from '../definitions/client';
import UploadSensorsModal from '../components/UploadSensorsModal';
import useSensorStore from '../store/sensorStore';
import useClientStore from '../store/clientStore';
import useFetchSensors from '../hooks/useFetchSensors';
import useDeleteSensor from '../hooks/useDeleteSensor';
import useUserStore from '../store/userStore';
import useDashboardContextStore from '../store/dashboardContextStore';
import useFetchSensorUseCases from '../hooks/useFetchSensorUseCases';
import useFetchSensorStatuses from '../hooks/useFetchSensorStatuses'
import useFetchClients from '../hooks/useFetchClients';
import useFetchSensorModels from '../hooks/useFetchSensorModels';
import useEditSensor from '../hooks/useEditSensor';
import {
  STATUSES_WITH_CUSTOMER_NEEDED,
  IPAD_MEDIA_QUERY_WIDTH,
  MOBILE_MEDIA_QUERY_WIDTH,
  STATUSES_WITH_CUSTOMER_NOT_MANDATORY,
} from '../utils/constants'
import { objectCleaner } from '../utils/helpers';
import theme from '../theme';

const SensorTableEditBtn = ({ id }) => {
  const navigate = useNavigate();
  const { setRowModesModel, rowModesModel, quickEditSensorId } = useDashboardContextStore();

  const onClick = (e) => {
    e.stopPropagation();
    navigate(`/edit-sensor/${id}`);
    if (quickEditSensorId) {
      setRowModesModel({
        ...rowModesModel,
        [quickEditSensorId]: { mode: GridRowModes.View, ignoreModifications: true },
      })
    };
  };

  return <EditNoteIcon sx={{ cursor: 'pointer', fontSize: 32, mt: '-4px' }} onClick={onClick} />;
};

const SensorTableQuickEditBtn = ({ id }) => {
  const { setQuickEditSensorId, setRowModesModel, rowModesModel, quickEditSensorId } = useDashboardContextStore();
  const onClick = () => {
    setQuickEditSensorId(id);
    if (quickEditSensorId) {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'sensorId' },
        [quickEditSensorId]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    } else {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.Edit },
      });
    }
  };

  return <EditIcon sx={{ cursor: 'pointer', mr: '7px' }} onClick={onClick} />;
};

const prepareQuickEditFormData = ({
  quickEditState,
  sensorUseCases,
  sensorModels,
  clients,
  quickEditSensorStatus,
}) => {
  const clientNameEditValue = quickEditState.clientName?.value;
  const client = clients.find(({ name }) => name === clientNameEditValue);
  const useCaseEditValue = quickEditState?.sensorUseCase?.value;
  const siteName = quickEditState.siteName?.value;
  const siteId = client
    ? client.sites.find(({ name }) => name === siteName)?.id
    : clients.find(({ isClient }) => !isClient).sites.find(({ name }) => name === siteName)?.id;
  const useCaseId = useCaseEditValue !== 'None'
    ? sensorUseCases.find(({ description }) => description === quickEditState.sensorUseCase?.value)?.id
    : null;
  const isSiteIdRequired = quickEditSensorStatus !== 'Sold';
  if (!siteId && isSiteIdRequired) {
    return NotificationManager.error('Location is required!');
  };
  if (quickEditSensorStatus === 'Installed' && !useCaseId) {
    return NotificationManager.error('Use case is required!');
  };
  return ({
    useCaseId: useCaseId,
    sensorModelId: sensorModels.find(({ description }) => description === quickEditState.sensorModel?.value)?.id,
    clientId: client?.id,
    siteId,
  });
};

const SensorActions = ({ params }) => {
  const { id, row }: { id: GridRowId, row: SensorsListItem } = params;
  const {
    quickEditSensorId,
    setQuickEditSensorId,
    setRowModesModel,
    rowModesModel,
    quickEditState,
  } = useDashboardContextStore();
  const { sensorsList, sensorModels, sensorStatuses, sensorUseCases } = useSensorStore();
  const { clients } = useClientStore();
  const { editSensor } = useEditSensor();

  const { deleteSensor } = useDeleteSensor();

  const [deleteId, setDeleteId] = useState<GridRowId>('');

  const quickEditSensor = sensorsList.find(({ id }) => id === quickEditSensorId);
  
  const quickEditSensorStatus = quickEditSensor?.sensorStatus;

  const onCancelClick = () => {
    setQuickEditSensorId();
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  }

  const handleSaveClick = () => {
    const preparedData = prepareQuickEditFormData({
      quickEditState,
      sensorUseCases,
      sensorModels,
      clients,
      quickEditSensorStatus,
    });
    if (!preparedData) return;
    const data = objectCleaner({ ...quickEditSensor, ...preparedData }) as SensorDTO;
    const defaultClient = clients.find(client => !client.isClient);
    const reqData = {
      clientId: defaultClient?.id,
      ...data,
    };
    editSensor(reqData, id);
    setQuickEditSensorId();
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const onDeleteClick = () => {
    setDeleteId(id);
  };

  const onDeleteDialogClose = () => {
    setDeleteId('');
  };

  const handleDeleteClick = () => {
    deleteSensor(id);
    setDeleteId('');
  }

  const allowToRemove = !['Sold', 'Installed'].includes(row.sensorStatus);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 13 }}>
      <Dialog open={!!deleteId} onClose={onDeleteDialogClose}>
        <DialogTitle>Are you sure you want to delete this sensor?</DialogTitle>
        <div style={{ display: 'flex' }}>
          <Button sx={{ flex: 1 }} color="error" startIcon={<DeleteIcon />} onClick={handleDeleteClick}>
            Confirm
          </Button>
          <Button sx={{ flex: 1 }} color="primary" startIcon={<CancelIcon />} onClick={onDeleteDialogClose}>
            No
          </Button>
        </div>
      </Dialog>
      {quickEditSensorId === id ? (
        <>
          <Check
            onClick={handleSaveClick}
            sx={{ cursor: 'pointer', fontSize: 27 }}
          />
          <CancelIcon
            onClick={onCancelClick}
            sx={{ cursor: 'pointer', fontSize: 27 }}
          />
        </>
      ) : (
        <>
          <SensorTableQuickEditBtn id={id} />
          <SensorTableEditBtn id={id} />
          {allowToRemove ? (
            <DeleteIcon
              onClick={onDeleteClick}
              sx={{ cursor: 'pointer', fontSize: 25 }}
            />
          ) : <div style={{ width: 25 }} />}
        </>
      )}
    </div>
  )
}

const Actions = styled('div')(() => ({
  marginBottom: 15,
  display: 'flex',
  justifyContent: 'flex-end',
}));

const AddSensorButton = styled(Button)(() => ({
  marginRight: 10,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    background: theme.palette.primary.dark,
 },
}));

export default function Dashboard() {
  const { user } = useUserStore();
  const { clients } = useClientStore();
  const { sensorsList, sensorUseCases, sensorStatuses, sensorModels } = useSensorStore();
  const { fetchSensors, loading } = useFetchSensors();
  const [ isOpenFileUploadModal, setIsOpenFileUploadModal ] = useState(false);
  const {
    rowModesModel,
    quickEditSensorId,
    quickEditState,
    setQuickEditState,
  } = useDashboardContextStore();
  const { fetchSensorsUseCases } = useFetchSensorUseCases();
  const { fetchSensorsStatuses } = useFetchSensorStatuses();
  const { fetchClients } = useFetchClients();
  const { fetchSensorsModels } = useFetchSensorModels();

  const isIpad = useMediaQuery(IPAD_MEDIA_QUERY_WIDTH);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY_WIDTH);

  useEffect(() => {
    if (user) {
      fetchSensors();
      fetchSensorsUseCases();
      fetchSensorsStatuses();
      fetchClients();
      fetchSensorsModels();
    }
  }, [user]);

  const quickEditSensor = sensorsList.find(({ id }) => id === quickEditSensorId);

  const quickEditClientId = quickEditState?.clientName && clients.find(({ name }) => name === quickEditState.clientName.value)?.id;

  const quickEditClient = quickEditSensor && clients.find(({ id }) => id === quickEditClientId);

  const basicUseCaseEditOptions = useMemo(() => sensorUseCases.map(({ description }) => description), [sensorUseCases]);

  const useCaseEditingOptions =
    quickEditState?.sensorStatus?.value === 'Installed'
      ? basicUseCaseEditOptions
      : [ 'None', ...basicUseCaseEditOptions ];

  const sitesSelectOptions = quickEditClient
    ? quickEditClient?.sites.map(({ name }) => name)
    : clients.find(({ isClient }) => !isClient)?.sites.map(({ name }) => name);
  
  const isCustomerEditable = quickEditSensor?.sensorStatus ? [
    ...STATUSES_WITH_CUSTOMER_NEEDED,
    ...STATUSES_WITH_CUSTOMER_NOT_MANDATORY,
  ].includes(quickEditSensor.sensorStatus) : false;

  const clientsSelectOptions = clients.reduce((acc: string[], curr: Client) => {
    if (curr.isClient) {
      acc.push(curr.name);
    };
    if (
      quickEditSensor?.sensorStatus &&
      STATUSES_WITH_CUSTOMER_NOT_MANDATORY.includes(quickEditSensor.sensorStatus) && 
      !curr.isClient
    ) {
      acc.push(curr.name);
    }
    return acc;
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'sensorId',
      headerName: 'Sensor ID',
      width: 200,
      resizable: false,
      editable: false,
    },
    {
      field: 'clientName',
      headerName: 'Customer',
      width: 200,
      resizable: false,
      editable: isCustomerEditable,
      type: 'singleSelect',
      valueOptions: clientsSelectOptions,
    },
    {
      field: 'sensorModel',
      headerName: 'Model Name',
      width: 200,
      resizable: false,
      editable: true,
      type: 'singleSelect',
      valueOptions: sensorModels.map(({ description }) => description),
    },
    {
      field: 'sensorUseCase',
      headerName: 'Use Case',
      width: 200,
      resizable: false,
      editable: true,
      type: 'singleSelect',
      valueOptions: useCaseEditingOptions,
    },
    {
      field: 'siteName',
      headerName: 'Location',
      width: 150,
      resizable: false,
      editable: quickEditSensor?.sensorStatus ? quickEditSensor.sensorStatus !== 'Sold' : false,
      type: 'singleSelect',
      valueOptions: sitesSelectOptions,
    },
    { field: 'sensorStatus',
      headerName: 'Status',
      width: 150,
      resizable: false,
      editable: false,
      type: 'singleSelect',
      valueOptions: sensorStatuses.map(({ description }) => description),
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      renderCell: (params) => <SensorActions params={params} />,
      filterable: false,
      hideable: false,
      resizable: false,
    },
  ];

  const handleGridStateChange = (gridState) => {
    const editState = quickEditSensorId && gridState.editRows[quickEditSensorId];

    if (isDeepEqual(editState, quickEditState)) return;
    setQuickEditState(editState);
  };

  const boxWidth = isMobile ? 370 : (isIpad ? 700 : '100%');

  const closeFileUploadModal = () => setIsOpenFileUploadModal(false);

  return (
    <div style={{ maxWidth: boxWidth }}>
      <UploadSensorsModal
        closeFileUploadModal={closeFileUploadModal}
        isOpenFileUploadModal={isOpenFileUploadModal}
      />
      <Actions>
        <Link to="/add-sensor">
          <AddSensorButton
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<PlusIcon />}
          >
            Add sensor
          </AddSensorButton>
        </Link>
        {!isMobile ? (
          <div>
            <AddSensorButton
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              onClick={() => setIsOpenFileUploadModal(true)}
            >
              Upload sensors
            </AddSensorButton>
          </div>
        ) : null}
      </Actions>
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          onStateChange={handleGridStateChange}
          rows={sensorsList}
          loading={loading}
          columns={columns}
          editMode="row"
          getRowId={(row: SensorsListItem) => row.id}
          rowModesModel={rowModesModel}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      </div>
    </div>
  );
}

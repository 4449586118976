import React, { useState } from 'react';
import Box from '@mui/material/Box';
import AddCommentIcon from '@mui/icons-material/AddComment';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import useAddSensorComment from '../hooks/useAddSensorComment';
import theme from '../theme';
import Textarea from './Textarea';

const Wrap = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
})

const AddCommentBtn = styled(Button)(() => ({
  backgroundColor: theme.palette.primary.main,
  height: 53,
  width: 53,
  color: '#fff',
  '&:hover': {
    background: theme.palette.primary.dark,
  },
  marginLeft: 10,
  borderRadius: 5,
  position: 'absolute',
  bottom: 0,
  top: 1,
  right: -70,
  zIndex: 5,
}));

const InputWrap = styled('div')({
  margin: '5px 3px 0px',
  width: 400,
});

const CommentsForm = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [value, setValue] = useState<string>('');
  const toggleShowInput = () => {
    setOpenDialog(prev => !prev);
    setValue('');
  };

  const { addSensorComment } = useAddSensorComment();

  const onCommentTextChange = (e) => {
    const { value } = e.target;
    setValue(value);
  };

  const handleSave = () => {
    addSensorComment(value);
    toggleShowInput();
  };

  return (
    <Wrap>
      <Dialog open={openDialog} onClose={toggleShowInput}>
        <DialogTitle>Add New Comment</DialogTitle>
        <InputWrap>
          <Textarea
            aria-label="minimum height"
            placeholder="Add comment"
            value={value}
            onChange={onCommentTextChange}
          />
        </InputWrap>
        <div style={{ display: 'flex' }}>
          <Button disabled={value.length < 3} sx={{ flex: 1 }} color="primary" startIcon={<CheckIcon />} onClick={handleSave}>
            Add
          </Button>
          <Button sx={{ flex: 1 }} color="error" startIcon={<CloseIcon />} onClick={toggleShowInput}>
            close
          </Button>
        </div>
      </Dialog>
      <AddCommentBtn onClick={toggleShowInput}><AddCommentIcon /></AddCommentBtn>
    </Wrap>
  );
};

export default CommentsForm;

import * as React from 'react';
import useCreateSensor from '../hooks/useCreateSensor';
import SensorForm from '../components/SensorForm';

export default function AddSensor() {
  const { createSensor, loading: submitLoading } = useCreateSensor();

  return (
    <SensorForm onSubmitRequest={createSensor} submitLoading={submitLoading} />
  );
}
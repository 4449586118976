import React, { useEffect } from 'react';
import useSensorStore from '../store/sensorStore';
import useEditSensor from '../hooks/useEditSensor';
import useFetchSensorById from '../hooks/useFetchSensorById';
import useFetchSensorInstallationHistory from '../hooks/useFetchSensorInstallationHistory';
import useFetchSensorCommentHistory from '../hooks/useFetchSensorCommentHistory'

import CommentsHistory from '../components/CommentsHistoryTable';
import InstallationHistoryTable from '../components/InstallationHistoryTable';
import SensorForm from '../components/SensorForm';
import PageLoader from '../components/PageLoader';

const sortComments = (comments: CommentsHistory[]) => comments.sort((a, b) => {
  const dateA = new Date(a.createdOn);
  const dateB = new Date(b.createdOn);
  return dateB.getTime() - dateA.getTime(); // Newest first
});

export default function EditSensor() {
  const { fetchSensor, loading } = useFetchSensorById();
  const { fetchSensorInstallationHistory, loading: installationHistoryLoading } = useFetchSensorInstallationHistory();
  const { fetchSensorCommentHistory, loading: commentHistoryLoading } = useFetchSensorCommentHistory();
  const { editSensor, loading: submitLoading } = useEditSensor();

  const { editSensorData, installationHistory, commentHistory } = useSensorStore();

  useEffect(() => {
    fetchSensor();
    fetchSensorInstallationHistory();
    fetchSensorCommentHistory();
  }, []);

  const sortedComments = sortComments(commentHistory);

  const mostRecentComment = sortedComments[0]?.comment;

  return (loading || installationHistoryLoading || commentHistoryLoading) ? (
    <PageLoader />
  ) : (
    <>
      <SensorForm
        onSubmitRequest={editSensor}
        submitLoading={submitLoading}
        editData={editSensorData!}
        mostRecentComment={mostRecentComment}
      />
      {!!installationHistory.length && (
        <InstallationHistoryTable installationHistory={installationHistory} />
      )}
      {!!commentHistory.length && (
        <CommentsHistory commentHistory={commentHistory} />
      )}
    </>
  );
};

import { create } from 'zustand';
import { User } from '../definitions/user';

interface UserStore {
  user: User | null;
  updateUser: (user: User | null) => void;

  loading: boolean;
  updateLoading: (loading: boolean) => void;
}

const useUserStore = create<UserStore>()((set) => ({
  user: null,
  updateUser: (user) => set({ user }),
  loading: false,
  updateLoading: (loading) => set({ loading }),
}));

export default useUserStore;
import { create } from 'zustand';
import { Client, Site } from '../definitions/client';

interface ClientStore {
  clients: Client[];
  updateClients: (clients: Client[]) => void;

  allSites: Site[],
  updateAllSites: (allSites: Site[]) => void,

  loading: boolean;
  updateLoading: (loading: boolean) => void;
}

const useClientStore = create<ClientStore>()((set) => ({
  clients: [],
  updateClients: (clients) => set({ clients }),

  allSites: [],
  updateAllSites: (allSites) => set({ allSites }),

  loading: false,
  updateLoading: (loading) => set({ loading }),
}));

export default useClientStore;
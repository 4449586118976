import { useState } from 'react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { useNavigate, useParams } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import useSensorStore from '../store/sensorStore';
import { msalConfig } from '../auth/authConfig';
import { errorHandler } from '../utils/helpers';
import { CommentHistory } from '../definitions/sensor';

interface FetchData {
  id: string | number;
  token: string | null;
  updateCommentHistory: (data: CommentHistory[]) => void;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
};

const fetchData = async ({
    id,
    token,
    updateCommentHistory,
    setError,
    setLoading,
  }: FetchData) => {
  setLoading(true);
  try {
    const path = `${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Sensor/Comment/${id}`
    const response = await axios.get(path, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    const sensor = response.data;
    updateCommentHistory(sensor);
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    setLoading(false);
  }
};

interface FetchSensors {
  fetchSensorCommentHistory: () => void;
  loading: boolean;
  error: Error | null;
};

const useFetchSensorCommentHistory = (): FetchSensors => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const { updateCommentHistory } = useSensorStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchSensorCommentHistory = () => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };
    if (!id) return navigate('/');
    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => fetchData({
        id,
        token: response.accessToken,
        updateCommentHistory,
        setError,
        setLoading,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { fetchSensorCommentHistory, loading, error };
};

export default useFetchSensorCommentHistory;

import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import { CommentHistory } from '../definitions/sensor';
import { MOBILE_MEDIA_QUERY_WIDTH } from '../utils/constants';
import { formatDateToCDT } from '../utils/helpers';
import theme from '../theme';

const Title = styled('div')({
  display: 'flex',
  marginBottom: 10,
  fontSize: 24,
  justifyContent: 'center',
});

const Comment = styled('div')({
  border: `1px solid ${theme.palette.primary.dark}`,
  borderRadius: 5,
  width: 450,
  marginBottom: 10,
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
});

const CommentsList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const CommentFooter = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 5,
  backgroundColor: theme.palette.primary.dark,
  color: '#fff',
  borderRadius: '0px 0px 4px 4px',
  padding: 5,
});

const CommentText = styled('div')({
  padding: 5,
  paddingBottom: 0,
});

export default function CommentsHistoryTable({
  commentHistory
}: {
  commentHistory: CommentHistory[],
}) {
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY_WIDTH);
  const blockWidth = isMobile ? 350 : 400;
  return (
    <div style={{ marginTop: 20, marginBottom: 50 }}>
      <Title>Comments History</Title>
      <CommentsList>
        {commentHistory.map(({
          comment,
          createdOn,
          createdBy,
        }: CommentHistory) => (
          <Comment sx={{ width: blockWidth }}>
            <CommentText>{comment}</CommentText>
            <CommentFooter>
              <div>{formatDateToCDT(createdOn)}</div>
              <div style={{ maxWidth: '50%' }}>{createdBy}</div>
            </CommentFooter>
          </Comment>
        ))}
      </CommentsList>
    </div>
  )
};

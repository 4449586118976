import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import theme from '../theme';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface InputFileUpload {
  text: string;
  handleFileUpload: (file: string) => void,
  accept?: string,
};

const InputButton = styled(Button)(() => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    background: theme.palette.primary.dark,
 },
}));

export default function InputFileUpload({ text, handleFileUpload, accept }: InputFileUpload) {
  const onChange = (e) => handleFileUpload(e.target.files[0]);
  return (
    <InputButton
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      onChange={(e) => onChange(e)}
    >
      {text}
      <VisuallyHiddenInput
        type="file"
        accept={accept}
      />
    </InputButton>
  );
}
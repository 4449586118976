import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import useUploadSensors from '../hooks/useUploadSensors';
import InputFileUpload from '../components/InputFileUpload';
import useSensorStore from '../store/sensorStore';
import { UploadResponse } from '../definitions/sensor';
import PageLoader from './PageLoader';
import theme from '../theme';
import { IPAD_MEDIA_QUERY_WIDTH, MOBILE_MEDIA_QUERY_WIDTH } from '../utils/constants';

const ContentWrap = styled('div')(() => ({
  padding: 10,
  paddingBottom: 0,
  display: 'flex',
}));

const TemplateLink = styled('a')(() => ({
  textDecoration: 'none',
  marginLeft: 10,
  marginTop: 5,
}));

const DialogHeader = styled('div')(() => ({
  padding: 5,
  display: 'flex',
  justifyContent: 'space-between',
}));

interface UploadSensorsModal {
  isOpenFileUploadModal: boolean;
  closeFileUploadModal: () => void;
};

const columns = [
  {
    field: 'sensorId',
    headerName: 'Sensor Id',
    flex: 1,
    resizable: false,
  },
  {
    field: 'messageResponse',
    headerName: 'Message Response',
    flex: 1,
    editable: true,
    resizable: false,
  },
  {
    field: 'isProcessed',
    headerName: 'Processed',
    flex: 1,
    resizable: false,
    renderCell: ({ value }: GridRenderCellParams<any, boolean>) => <div style={{ marginTop: 6 }}>{value ? <DoneIcon sx={{ color: theme.palette.success.main }} />  : <CancelIcon sx={{ color: theme.palette.error.main }} />}</div>
  },
];

const templateDownloadLink = `${window.__env.REACT_APP_SENSOR_TEMPLATE_DOWNLOAD_LINK || process.env.REACT_APP_SENSOR_TEMPLATE_DOWNLOAD_LINK}`;

const UploadSensorsModal = ({
  isOpenFileUploadModal,
  closeFileUploadModal,
}: UploadSensorsModal) => {
  const { uploadSensors, loading } = useUploadSensors();
  const { uploadResponse, uploadResponseCounters, updateUploadResponse, updateUploadResponseCounters } = useSensorStore();

  const isIpad = useMediaQuery(IPAD_MEDIA_QUERY_WIDTH);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY_WIDTH);
  const maxWidth = isMobile ? 350 : (isIpad ? 1000 : 1400);

  const onClose = () => {
    closeFileUploadModal();
    updateUploadResponse([]);
    updateUploadResponseCounters({ processed: 0, failed: 0 });
  };

  const { processed, failed } = uploadResponseCounters;

  const isUploaded = !!uploadResponse.length;

  return (
    <Dialog maxWidth={isUploaded ? maxWidth : 300} fullWidth={isUploaded} open={isOpenFileUploadModal} onClose={onClose}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <ContentWrap>
            <InputFileUpload
              text="Upload"
              handleFileUpload={uploadSensors}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <TemplateLink href={templateDownloadLink}>
              Download template
            </TemplateLink>
          </ContentWrap>
          {isUploaded ? (
            <>
              <div style={{ margin: 10, display: 'flex', justifyContent: 'center' }}>
                <span style={{
                  color: processed > 0 ? theme.palette.success.main : theme.palette.error.main,
                  marginRight: 5,
                }}>
                  Processed: {processed};
                </span>
                <span style={{ color: failed < 1 ? theme.palette.success.main : theme.palette.error.main }}>
                  Failed: {failed}
                </span>
              </div>
              <div style={{ maxWidth: maxWidth }}>
                <DataGrid
                  rows={uploadResponse}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  getRowId={(row: UploadResponse) => row.sensorId}
                  pageSizeOptions={[5, 10]}
                  disableRowSelectionOnClick
                />
              </div>
            </>
          ) : null}
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default UploadSensorsModal;

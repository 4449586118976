import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { useMsal } from '@azure/msal-react';
import useFetchClients from './useFetchClients';
import { msalConfig } from '../auth/authConfig';
import { errorHandler } from '../utils/helpers';
import useUserStore from '../store/userStore';

interface EditSiteRequest {
  id: string | number;
  token: string | null;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
  fetchClients: () => void;
  data: EditSiteDTO;
};

const editSiteRequest = async ({
    token,
    setError,
    setLoading,
    fetchClients,
    data,
    id,
  }: EditSiteRequest) => {
  try {
    const response = await axios.put(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Site/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    if (response.status === 200) {
      NotificationManager.success('Site is successfully edited');
    }
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    fetchClients();
    setLoading(false);
  }
};

interface EditSiteDTO { name: string, siteId: string, clientId: number, userId?: string | number };

interface EditSite {
  editSite: (id: string | number, formData: EditSiteDTO) => void;
  loading: boolean;
  error: Error | null;
};

const useEditSite = (): EditSite => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { fetchClients } = useFetchClients();
  
  const { user } = useUserStore();

  const editSite = (id, formData: EditSiteDTO) => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };

    if (!user) return navigate('/sign-in');

    const data: EditSiteDTO = {
      ...formData,
      userId: user.id,
    };

    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => editSiteRequest({
        id,
        token: response.accessToken,
        setError,
        fetchClients,
        setLoading,
        data,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { editSite, loading, error };
};

export default useEditSite;

import { red, green } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import './theme.css'

const primaryColorLight = '#fff'
const secondaryColor = '#ffe4cf'
const theme = createTheme({
  palette: {
    primary: {
      main: '#f27a54',
      dark: '#414042',
      light: primaryColorLight,
    },
    secondary: {
      main: secondaryColor,
      dark: secondaryColor,
      light: secondaryColor,
    },
    success: {
      main: green[700],
    },
    error: {
      main: red.A400,
    },
    background: {
      default: primaryColorLight,
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Sen',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    caption: {
      fontFamily: 'Sen',
    },
  },
})

export default theme

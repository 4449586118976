import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
  GridColDef,
  GridRowId,
} from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import useSensorStore from '../../store/sensorStore';
import useVendorStore from '../../store/vendorStore';
import useClientStore from '../../store/clientStore';

import useFetchSensors from '../../hooks/useFetchSensors';
import useFetchSensorUseCases from '../../hooks/useFetchSensorUseCases';
import useFetchSensorStatuses from '../../hooks/useFetchSensorStatuses';
import useFetchSensorModels from '../../hooks/useFetchSensorModels';
import useFetchSensorPurchaseOrders from '../../hooks/useFetchSensorPurchaseOrders';
import useFetchVendors from '../../hooks/useFetchVendors';
import useFetchClients from '../../hooks/useFetchClients';


import useCreateSensorUseCase from '../../hooks/useCreateSensorUseCase';
import useEditSensorUseCase from '../../hooks/useEditSensorUseCase';
import useDeleteSensorUseCase from '../../hooks/useDeleteSensorUseCase';

import useCreateSensorStatus from '../../hooks/useCreateSensorStatus';
import useEditSensorStatus from '../../hooks/useEditSensorStatus';
import useDeleteSensorStatus from '../../hooks/useDeleteSensorStatus';

import useCreateSensorModel from '../../hooks/useCreateSensorModel';
import useEditSensorModel from '../../hooks/useEditSensorModel';
import useDeleteSensorModel from '../../hooks/useDeleteSensorModel';

import useCreateSensorPurchaseOrder from '../../hooks/useCreateSensorPurchaseOrder';
import useEditSensorPurchaseOrder from '../../hooks/useEditSensorPurchaseOrder';
import useDeleteSensorPurchaseOrder from '../../hooks/useDeleteSensorPurchaseOrder';

import useCreateVendor from '../../hooks/useCreateVendor';
import useEditVendor from '../../hooks/useEditVendor';
import useDeleteVendor from '../../hooks/useDeleteVendor';

import useCreateVendorContact from '../../hooks/useCreateVendorContact';
import useEditVendorContact from '../../hooks/useEditVendorContact';
import useDeleteVendorContact from '../../hooks/useDeleteVendorContact';

import useCreateSite from '../../hooks/useCreateSite';
import useEditSite from '../../hooks/useEditSite';
import useDeleteSite from '../../hooks/useDeleteSite';

import PicklistTable from './PicklistTable';
import { SensorsListItem } from '../../definitions/sensor';
import { Site } from '../../definitions/client';
import { IPAD_MEDIA_QUERY_WIDTH, MOBILE_MEDIA_QUERY_WIDTH } from '../../utils/constants';


const DEFAULT_COLUMNS: GridColDef[] = [
  {
    field: 'description',
    headerName: 'Name',
    width: 200,
    resizable: false,
    editable: true,
  },
];

const VENDORS_COLUMNS: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    resizable: false,
    editable: true,
  },
];

const SENSOR_MODELS_COLUMNS: GridColDef[] = [
  ...DEFAULT_COLUMNS,
  {
    field: 'partDescription',
    headerName: 'Part Description',
    width: 250,
    resizable: false,
    editable: true,
  },
];

const PURCHASE_ORDER_COLUMNS: GridColDef[] = [
  ...DEFAULT_COLUMNS,
  {
    field: 'number',
    headerName: 'Number',
    width: 200,
    resizable: false,
    editable: true,
  },
];

interface DefaultRow {
  id: GridRowId;
  description: string;
};

interface PartDecRow extends DefaultRow {
  partDescription: string;
};

interface VendorRow {
  id: GridRowId;
  name: string;
}

interface SiteRow {
  id: GridRowId;
  siteId: string;
  name: string;
}

interface PORow extends DefaultRow {
  number: string;
}

interface PickListConfigutation {
  id: string,
  title: string,
  rows: DefaultRow[] | PartDecRow[] | VendorRow[] | PORow[] | SiteRow[],
  columns: GridColDef[],
  constants?: string[],
  createHandler: (data: any) => void,
  editHandler: (id: GridRowId, data: any) => void,
  deleteHandler: (id: GridRowId) => void,
  deleteRiskAnalyser: (id: GridRowId) => string[];
  isSmall?: boolean,
};

const getSensorsInUse = (
  id: GridRowId,
  sensorsList: SensorsListItem[],
  recordName: string,
): string[] => sensorsList.reduce(
  (acc: string[], curr: SensorsListItem) => {
    if (curr[recordName] === id) {
      acc.push(curr.sensorId);
    }
    return acc;
  },
[]);

export default function Picklist() {
  const isIpad = useMediaQuery(IPAD_MEDIA_QUERY_WIDTH);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY_WIDTH);

  const [screenLoading, setScreenLoading] = useState(true);

  const { fetchSensors } = useFetchSensors();

  const { fetchSensorsUseCases } = useFetchSensorUseCases();
  const { fetchSensorsStatuses } = useFetchSensorStatuses();
  const { fetchSensorsModels } = useFetchSensorModels();
  const { fetchSensorPurchaseOrders } = useFetchSensorPurchaseOrders();
  const { fetchVendors } = useFetchVendors();
  const { fetchClients } = useFetchClients();

  const { createSensorUseCase } = useCreateSensorUseCase();
  const { editSensorUseCase } = useEditSensorUseCase();
  const { deleteSensorUseCase } = useDeleteSensorUseCase();

  const { createSensorStatus } = useCreateSensorStatus();
  const { editSensorStatus } = useEditSensorStatus();
  const { deleteSensorStatus } = useDeleteSensorStatus();

  const { createSensorModel } = useCreateSensorModel();
  const { editSensorModel } = useEditSensorModel();
  const { deleteSensorModel } = useDeleteSensorModel();

  const { createSensorPurchaseOrder } = useCreateSensorPurchaseOrder();
  const { editSensorPurchaseOrder } = useEditSensorPurchaseOrder();
  const { deleteSensorPurchaseOrder } = useDeleteSensorPurchaseOrder();

  const { createVendor } = useCreateVendor();
  const { editVendor } = useEditVendor();
  const { deleteVendor } = useDeleteVendor();

  const { createVendorContact } = useCreateVendorContact();
  const { editVendorContact } = useEditVendorContact();
  const { deleteVendorContact } = useDeleteVendorContact();

  const { createSite } = useCreateSite();
  const { editSite } = useEditSite();
  const { deleteSite } = useDeleteSite();

  const { allSites, clients } = useClientStore();

  const {
    sensorsList,
    sensorUseCases,
    sensorStatuses,
    sensorModels,
    sensorPurchaseOrders,
  } = useSensorStore();

  const { vendors, allVendorsContacts } = useVendorStore();

  useEffect(() => {
    setScreenLoading(true);
    if (!sensorsList.length) { 
      fetchSensors();
     }
    Promise.allSettled([
      fetchSensorsUseCases(),
      fetchSensorsStatuses(),
      fetchSensorsModels(),
      fetchSensorPurchaseOrders(),
      fetchVendors(),
      fetchClients(),
    ]).finally(() => setScreenLoading(false))
  }, []);

  const VENDORS_CONTACTS_COLUMNS: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      resizable: false,
      editable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 240,
      resizable: false,
      editable: true,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 150,
      resizable: false,
      editable: true,
    },
    {
      field: 'vendorName',
      headerName: 'Vendor Name',
      width: 200,
      type: 'singleSelect',
      valueOptions: vendors.map(({ name }) => name),
      resizable: false,
      editable: true,
    },
  ];

  const SITES_COLUMNS: GridColDef[] = [
    {
      field: 'siteId',
      headerName: 'Site Id',
      width: 200,
      resizable: false,
      editable: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      resizable: false,
      editable: true,
    },
    {
      field: 'clientName',
      headerName: 'Client Name',
      width: 200,
      type: 'singleSelect',
      valueOptions: clients.map(({ name }) => name),
      resizable: false,
      editable: true,
    },
  ];

  const smallPickLists: PickListConfigutation[] = [
    {
      id: randomId(),
      title: 'Sensor Use Cases',
      rows: sensorUseCases,
      columns: DEFAULT_COLUMNS,
      createHandler: ({ description }) => createSensorUseCase({ description }),
      editHandler: (id, { description }) => editSensorUseCase(id, { description }),
      deleteHandler: (id) => deleteSensorUseCase(id),
      deleteRiskAnalyser: (id) => getSensorsInUse(id, sensorsList, 'useCaseId'),
      isSmall: true,
    },
    {
      id: randomId(),
      title: 'Sensor Statuses',
      rows: sensorStatuses,
      columns: DEFAULT_COLUMNS,
      createHandler: ({ description }) => createSensorStatus({ description }),
      editHandler: (id, { description }) => editSensorStatus(id, { description }),
      deleteHandler: (id) => deleteSensorStatus(id),
      deleteRiskAnalyser: (id) => getSensorsInUse(id, sensorsList, 'statusId'),
      isSmall: true,
      constants: ['Installed', 'Sold', 'RMA', 'Used', 'Written Off'],
    },
    {
      id: randomId(),
      title: 'Vendors',
      rows: vendors,
      columns: VENDORS_COLUMNS,
      createHandler: ({ name }) => createVendor({ name }),
      editHandler: (id, { name }) => editVendor(id, { name }),
      deleteHandler: (id) => deleteVendor(id),
      deleteRiskAnalyser: (id) => getSensorsInUse(id, sensorsList, 'vendorId'),
      isSmall: true,
    },
  ];

  const pickLists: PickListConfigutation[] = [
    {
      id: randomId(),
      title: 'Sensor Models',
      rows: sensorModels,
      columns: SENSOR_MODELS_COLUMNS,
      createHandler: ({ description, partDescription }) => createSensorModel({ description, partDescription }),
      editHandler: (id, data) => editSensorModel(id, data),
      deleteHandler: (id) => deleteSensorModel(id),
      deleteRiskAnalyser: (id) => getSensorsInUse(id, sensorsList, 'vendorId'),
      isSmall: !isIpad && true,
    },
    {
      id: randomId(),
      title: 'Sensor Purchase Order',
      rows: sensorPurchaseOrders,
      columns: PURCHASE_ORDER_COLUMNS,
      createHandler: (data) => createSensorPurchaseOrder(data),
      editHandler: (id, { description, number }) => editSensorPurchaseOrder(id, { description, number }),
      deleteHandler: (id) => deleteSensorPurchaseOrder(id),
      deleteRiskAnalyser: (id) => getSensorsInUse(id, sensorsList, 'purchaseOrderId'),
      isSmall: !isIpad && true,
    },
    {
      id: randomId(),
      title: 'Vendors Contacts',
      rows: allVendorsContacts,
      columns: VENDORS_CONTACTS_COLUMNS,
      createHandler: ({ name, email, phoneNumber, vendorName }) => {
        const vendorId = vendors.find(vendor => vendor.name === vendorName)?.id;
        if (!vendorId) return;
        createVendorContact({ name, email, phoneNumber, vendorId });
      },
      editHandler: (id, { name, email, phoneNumber, vendorName }) => {
        const vendorId = vendors.find(vendor => vendor.name === vendorName)?.id;
        if (!vendorId) return;
        editVendorContact(id, { name, email, phoneNumber, vendorId });
      },
      deleteHandler: (id) => deleteVendorContact(id),
      deleteRiskAnalyser: (id) => getSensorsInUse(id, sensorsList, 'vendorContactId'),
    },
    {
      id: randomId(),
      title: 'Sites',
      rows: [...allSites].sort((a: Site, b: Site) => a.name.localeCompare(b.name)),
      columns: SITES_COLUMNS,
      createHandler: ({ name, siteId, clientName }) => {
        const clientId = clients.find(client => client.name === clientName)?.id;
        if (!clientId) return;
        createSite({ name, siteId, clientId });
      },
      editHandler: (id, { name, siteId, clientName }) => {
        const clientId = clients.find(client => client.name === clientName)?.id;
        if (!clientId) return;
        editSite(id, { name, siteId, clientId });
      },
      constants: ['Unknown'],
      deleteHandler: (id) => deleteSite(id),
      deleteRiskAnalyser: (id) => getSensorsInUse(id, sensorsList, 'siteId'),
    },
  ];

  const boxWidth = isMobile ? 350 : (isIpad ? 700 : null);

  return screenLoading ? (
      <div>
        <CircularProgress />
      </div>
    ) : (
      <Box sx={{ flexGrow: 1, width: boxWidth }}>
        {isIpad ? [...smallPickLists, ...pickLists].map(({
            id,
            title,
            rows,
            columns,
            constants,
            createHandler,
            editHandler,
            deleteHandler,
            deleteRiskAnalyser,
            isSmall,
          }) => (
            <div key={id} style={{ marginBottom: 50 }}>
              <PicklistTable
                title={title}
                rows={rows}
                columns={columns}
                constants={constants}
                createHandler={createHandler}
                editHandler={editHandler}
                deleteHandler={deleteHandler}
                deleteRiskAnalyser={deleteRiskAnalyser}
                isSmall={isSmall}
              />
            </div>
          )) : (
          <>
            <Grid sx={{ mb: 5 }} container spacing={4}>
              {smallPickLists.map(({
                id,
                title,
                rows,
                columns,
                createHandler,
                editHandler,
                deleteHandler,
                deleteRiskAnalyser,
                constants,
                isSmall,
              }) => (
                <Grid key={id} item xs={4}>
                  <PicklistTable
                    title={title}
                    rows={rows}
                    columns={columns}
                    constants={constants}
                    createHandler={createHandler}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                    deleteRiskAnalyser={deleteRiskAnalyser}
                    isSmall={isSmall}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid sx={{ mb: 5 }} container spacing={2}>
              {pickLists.map(({
                id,
                title,
                rows,
                columns,
                constants,
                createHandler,
                editHandler,
                deleteHandler,
                deleteRiskAnalyser,
                isSmall,
              }) => (
                <Grid key={id} item xs={6}>
                  <PicklistTable
                    title={title}
                    rows={rows}
                    columns={columns}
                    constants={constants}
                    createHandler={createHandler}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                    deleteRiskAnalyser={deleteRiskAnalyser}
                    isSmall={isSmall}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Box>
    );
}
import React, { useState } from 'react';
import startCase from 'lodash.startcase';
import { NotificationManager } from 'react-notifications';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Check from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Close';
import {
  DataGrid,
  GridColDef,
  GridSlots,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowModes,
  GridRowId,
  GridRowModesModel,
  useGridApiRef,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Title = styled('div')({
  display: 'flex',
  marginBottom: 10,
  fontSize: 24,
});

function AddNewRecordAction({ setRows, setRowModesModel }) {
  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [{ id, description: '', name: '', number: null, isNew: true }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

interface PicklistTable {
  rows: { id: GridRowId, description?: string, number?: number | string, partDescription?: string, name?: string, isNew?: boolean }[];
  columns: GridColDef[];
  title: string;
  constants?: string[];
  deleteHandler: (id: GridRowId) => void,
  editHandler: (id: GridRowId, data: any) => void,
  createHandler: (data: any) => void,
  deleteRiskAnalyser: (id: GridRowId) => string[],
  isSmall?: boolean,
}

export default ({
  rows: originalRows,
  columns: originalColumns,
  title,
  constants,
  deleteHandler,
  editHandler,
  createHandler,
  deleteRiskAnalyser,
  isSmall,
}: PicklistTable) => {
  const apiRef = useGridApiRef();
  const [rows, setRows] = useState(originalRows);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [editRows, setEditRows] = useState({});
  const [deleteId, setDeleteId] = useState('');
  const [onDeleteInUseList, setOnDeleteInUseList] = useState<string[]>([]);

  const handleEditClick = (id: GridRowId) => {
    setRowModesModel(
      { [id]: { mode: GridRowModes.Edit } }
    );
  };

  const handleCancelClick = (id: GridRowId) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    setRows(originalRows);
  };

  const handleDeleteClick = (id: GridRowId) => {
    deleteHandler(id);
  };

  const handleSaveClick = (id: GridRowId) => {
    const requiredFields = Object.keys(editRows[id]);
    const data = requiredFields.reduce((
      acc: { data: {}, notValidFields: string[] },
      curr: string,
    ) => {
      const dataValue = editRows[id][curr]?.value;
      if (curr === 'email' && dataValue) {
        const notValidEmail = !emailPattern.test(dataValue);
        if (notValidEmail) {
          acc.notValidFields.push(startCase(curr));
          return acc;
        };
      }
      if (dataValue) {
        acc.data = {
          ...acc.data,
          [curr]: dataValue
        };
      } else {
        acc.notValidFields.push(startCase(curr));
      }
      return acc;
    }, {
      data: {},
      notValidFields: [],
    });
    if (data.notValidFields.length) {
      return NotificationManager.error(`${data.notValidFields.join('; ')} is not valid`);
    };
    rows.find(row => row.id === id)?.isNew
      ? createHandler(data.data)
      : editHandler(id, data.data);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const onDeleteClick = (id) => {
    setDeleteId(id)
    const inUseInList: string[] = deleteRiskAnalyser(id);
    setOnDeleteInUseList(inUseInList);
  };

  const columns = [
    ...originalColumns,
    {
      field: 'action',
      headerName: '',
      sortable: false,
      flex: isSmall ? 1 : null,
      with: 200,
      renderCell: (params) => {
        const { id, row } = params;
        if (constants?.includes(row.description)) return null;
        if (constants?.includes(row.name)) return null;
        if (Object.keys(rowModesModel).includes(id.toString())) {
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10 }}>
              <GridActionsCellItem
                icon={<Check />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={() => handleSaveClick(id)}
              />
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={() => handleCancelClick(id)}
                color="inherit"
              />
            </div>
          )
        }
        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10 }}>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              sx={{
                color: 'primary.main',
              }}
              onClick={() => handleEditClick(id)}
            />
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              className="textPrimary"
              onClick={() => onDeleteClick(id)}
              color="inherit"
            />
          </div>
        );
      },
      filterable: false,
      hideable: false,
      resizable: false,
    },
  ];

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleStateChange = (params) => {
    const { editRows } = params;
    setEditRows(editRows);
  };

  const onDeleteDialogClose = () => {
    setOnDeleteInUseList([]);
    setDeleteId('');
  };

  return (
    <div style={{ height: 420, width: '100%', marginBottom: 40 }}>
      <Title>{title}</Title>
      <Dialog open={!!deleteId} onClose={onDeleteDialogClose}>
        {onDeleteInUseList.length ? (
          <>
            <DialogTitle>This record is in use in. Are you sure you want to delete this item?</DialogTitle>
            <div style={{ marginLeft: 28 }}>Items that will be effected:</div>
            <div style={{
              marginLeft: 28,
              marginRight: 28,
              padding: 5,
              backgroundColor: '#d3d3d3',
              color: '#808080',
              borderRadius: 5,
            }}>
              {onDeleteInUseList.join('; ')}
            </div>
          </>
        ) : (
          <DialogTitle>Are you sure you want to delete this item?</DialogTitle>
        )}
        <div style={{ display: 'flex' }}>
          <Button sx={{ flex: 1 }} color="error" startIcon={<DeleteIcon />} onClick={() => handleDeleteClick(deleteId)}>
            Confirm
          </Button>
          <Button sx={{ flex: 1 }} color="primary" startIcon={<CancelIcon />} onClick={onDeleteDialogClose}>
            No
          </Button>
        </div>
      </Dialog>
      <DataGrid
        apiRef={apiRef}
        rows={rows}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onStateChange={handleStateChange}
        // loading={!rows}
        columns={columns}
        onRowEditStop={({ id, reason }) => {
          if (['rowFocusOut', 'enterKeyDown'].includes(reason as string)) {
            return handleSaveClick(id);
          }
        }}
        getRowId={(row) => row.id}
        editMode="row"
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        slots={{
          toolbar: AddNewRecordAction as GridSlots['toolbar'],
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        pageSizeOptions={[5, 10]}
      />
    </div>
  );
};

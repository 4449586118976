import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { useMsal } from '@azure/msal-react';
import useFetchVendors from './useFetchVendors';
import { msalConfig } from '../auth/authConfig';
import { errorHandler } from '../utils/helpers';
import useUserStore from '../store/userStore';

interface EditVendorRequest {
  id: string | number;
  token: string | null;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
  fetchVendors: () => void;
  data: EditModelDTO;
};

const editVendorRequest = async ({
    token,
    setError,
    setLoading,
    fetchVendors,
    data,
    id,
  }: EditVendorRequest) => {
  try {
    const response = await axios.put(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Vendor/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    if (response.status === 200) {
      NotificationManager.success('Vendor is successfully edited');
    }
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    fetchVendors();
    setLoading(false);
  }
};

interface EditModelDTO { name: string };

interface EditVendor {
  editVendor: (id: string | number, formData: EditModelDTO) => void;
  loading: boolean;
  error: Error | null;
};

const useEditVendor = (): EditVendor => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { fetchVendors } = useFetchVendors();

  const { user } = useUserStore();

  const editVendor = (id, formData: EditModelDTO) => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };

    if (!user) return navigate('/sign-in');

    const data: EditModelDTO = formData;

    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => editVendorRequest({
        id,
        token: response.accessToken,
        setError,
        fetchVendors,
        setLoading,
        data,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { editVendor, loading, error };
};

export default useEditVendor;

import React, { useEffect, useState } from 'react';
import { NotificationContainer } from 'react-notifications';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from '@azure/msal-react';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/LockTwoTone';
import { loginRequest } from './authConfig';
import useUserStore from '../store/userStore';
import useFetchUser from '../hooks/useFetchUser';
import theme from '../theme';
import logo from '../img/Scada_Lite_logo-01.png';


const BtnSignIn = styled(Button)(() => ({
  backgroundColor: theme.palette.primary.main,
  width: 87,
  marginTop: 30,
  '&:hover': {
    background: theme.palette.primary.dark,
 },
}));

const Wrap = styled('div')(() => ({
  display: 'flex',
  marginTop: 70,
  flexDirection: 'column',
  alignItems: 'center',
}));

const LockIconAvatar = styled(Avatar)(() => ({
  backgroundColor: theme.palette.secondary.main,
  marginBottom: 10,
}));

const SignIn = () => {
  const { user } = useUserStore();
  const { fetchUser } = useFetchUser();
  const msalContext = useMsal();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [error, setError] = useState<{message: string}>();

  useEffect(() => {
    if (isAuthenticated && !user) {
      fetchUser();
    }
    if (isAuthenticated && user) {
      navigate("/");
    }
  }, [isAuthenticated, user]);

  const onSignInButtonClick = async (event) => {
    event.preventDefault();
    try {
      await msalContext.instance.loginRedirect(loginRequest);
    } catch (e) {
      setError(e);
    }
  }

  return (
    <>
      <CssBaseline />
      <NotificationContainer />
      <Wrap>
        <LockIconAvatar>
          <LockIcon />
        </LockIconAvatar>
        <img style={{ width: 198 }} alt="SCADA Lite" src={logo} width="50%" />
        <BtnSignIn
          fullWidth={true}
          variant="contained"
          color="primary"
          onClick={onSignInButtonClick}
        >
          Sign In
        </BtnSignIn>
        {error && (
          <Typography color="error">{error.message}</Typography>
        )}
      </Wrap>
    </>
  );
};

export default SignIn;

import { useState } from 'react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import useSensorStore from '../store/sensorStore';
import { msalConfig } from '../auth/authConfig';
import { errorHandler } from '../utils/helpers';
import { PurchaseOrder } from '../definitions/sensor';

interface FetchData {
  token: string | null;
  updatePurchaseOrders: (data: PurchaseOrder[]) => void;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
};

const fetchData = async ({
    token,
    updatePurchaseOrders,
    setError,
    setLoading,
  }: FetchData) => {
  try {
    const response = await axios.get(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Sensor/PurchaseOrders`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    const useCases = response.data;
    updatePurchaseOrders(useCases);
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    setLoading(false);
  }
};

interface SensorUseCases {
  fetchSensorPurchaseOrders: () => void;
  loading: boolean;
  error: Error | null;
};

const useFetchSensorPurchaseOrders = (): SensorUseCases => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const { updatePurchaseOrders } = useSensorStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchSensorPurchaseOrders = () => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };
    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => fetchData({
        token: response.accessToken,
        updatePurchaseOrders,
        setError,
        setLoading,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { fetchSensorPurchaseOrders, loading, error };
};

export default useFetchSensorPurchaseOrders;

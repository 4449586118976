import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { useMsal } from '@azure/msal-react';
import useFetchSensorPurchaseOrders from './useFetchSensorPurchaseOrders';
import { msalConfig } from '../auth/authConfig';
import { errorHandler } from '../utils/helpers';
import useUserStore from '../store/userStore';

interface CreateSensorPurchaseOrderRequest {
  token: string | null;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
  fetchSensorPurchaseOrders: () => any;
  data: CreatePurchaseOrderDTO;
};

const createSensorPurchaseOrderRequest = async ({
    token,
    setError,
    setLoading,
    fetchSensorPurchaseOrders,
    data,
  }: CreateSensorPurchaseOrderRequest) => {
  try {
    const response = await axios.post(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Sensor/PurchaseOrder`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    if (response.status === 200) {
      NotificationManager.success('Sensor Purchase Order is successfully created');
    }
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    fetchSensorPurchaseOrders();
    setLoading(false);
  }
};

interface CreatePurchaseOrderDTO { description: string, userId?: string | number };

interface CreateSensorPurchaseOrder {
  createSensorPurchaseOrder: (formData: CreatePurchaseOrderDTO) => void;
  loading: boolean;
  error: Error | null;
};

const useCreateSensorPurchaseOrder = (): CreateSensorPurchaseOrder => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { fetchSensorPurchaseOrders } = useFetchSensorPurchaseOrders();
  
  const { user } = useUserStore();

  const createSensorPurchaseOrder = (formData: CreatePurchaseOrderDTO) => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };

    if (!user) return navigate('/sign-in');

    const data: CreatePurchaseOrderDTO = {
      ...formData,
      userId: user.id,
    };

    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => createSensorPurchaseOrderRequest({
        token: response.accessToken,
        setError,
        fetchSensorPurchaseOrders,
        setLoading,
        data,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { createSensorPurchaseOrder, loading, error };
};

export default useCreateSensorPurchaseOrder;

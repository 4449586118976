import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import useSensorStore from '../store/sensorStore';
import { msalConfig } from '../auth/authConfig';
import { errorHandler } from '../utils/helpers';
import { SensorsListItem } from '../definitions/sensor';

interface FetchUser {
  token: string | null;
  updateSensorsList: (data: SensorsListItem[]) => void;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
};

const fetchData = async ({
    token,
    updateSensorsList,
    setError,
    setLoading,
  }: FetchUser) => {
  setLoading(true);
  try {
    const path = `${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Sensor`
    const response = await axios.get(path, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    const sensors = response.data;
    updateSensorsList(sensors);
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    setLoading(false);
  }
};

interface FetchSensors {
  fetchSensors: () => void;
  loading: boolean;
  error: Error | null;
};

const useFetchSensors = (): FetchSensors => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const { updateSensorsList } = useSensorStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchSensors = () => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };
    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => fetchData({
        token: response.accessToken,
        updateSensorsList,
        setError,
        setLoading,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { fetchSensors, loading, error };
};

export default useFetchSensors;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './auth/authConfig';
import Dashboard from './pages/Dashboard';
import AddSensor from './pages/AddSensor';
import Picklist from './pages/Picklist';
import EditSensor from './pages/EditSensor';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import MainLayout from './components/MainLayout';
import SignIn from './auth/SignIn';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout><Dashboard /></MainLayout>,
  },
  {
    path: "/sign-in",
    element: <SignIn />
  },
  {
    path: "/add-sensor",
    element: <MainLayout><AddSensor /></MainLayout>,
  },
  {
    path: "/picklist",
    element: <MainLayout><Picklist /></MainLayout>,
  },
  {
    path: "/edit-sensor/:id",
    element: <MainLayout><EditSensor /></MainLayout>,
  },
]);

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <RouterProvider router={router} />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

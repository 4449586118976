import { SensorFormData } from "../../definitions/sensor";

export const FORM_DEFAULT_VALUES: SensorFormData = {
  sensorId: '',
  purchaseOrderId: null,
  sensorModelId: null,
  clientId: null, 
  useCaseId: null,
  siteId: null,
  statusId: null,
  vendorId: null,
  vendorContactId: null,
  cost: null,
  installDate: null,
  receivedDate: null,
  shippedDate: null,
  warrantyExpiration: null,
  receivedReplacement: null,
  writtenOffDate: null,
  rmaNumber: null,
  leadTime: '',
  replacement: '',
  comment: '',
};

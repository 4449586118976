import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { useMsal } from '@azure/msal-react';
import { msalConfig } from '../auth/authConfig';
import { SensorFormData, SensorDTO } from '../definitions/sensor';
import { errorHandler } from '../utils/helpers';
import useUserStore from '../store/userStore';

const prepareData = (data: SensorDTO): SensorDTO => ({
  ...data,
  cost: Number(data.cost) || 0,
  // rmaNumber: Number(data.rmaNumber) || 0,
});

interface CreateSensorRequest {
  token: string | null;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
  navigate: (string) => void,
  data: SensorDTO;
};

const createSensorRequest = async ({
  token,
  setError,
  setLoading,
  navigate,
  data,
}: CreateSensorRequest) => {
  try {
    const response = await axios.post(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Sensor`, prepareData(data), {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    if (response.status === 200) {
      NotificationManager.success('Sensor is successfully created')
      return navigate('/');
    }
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    setLoading(false);
  }
};

interface CreateSensor {
  createSensor: (formData: SensorFormData) => void;
  loading: boolean;
  error: Error | null;
};

const useCreateSensor = (): CreateSensor => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  
  const { user } = useUserStore();

  const createSensor = (formData: SensorFormData) => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };

    if (!user) return navigate('/sign-in');

    const data: SensorDTO = {
      ...formData,
      userId: user.id,
    };

    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => createSensorRequest({
        token: response.accessToken,
        setError,
        setLoading,
        navigate,
        data,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { createSensor, loading, error };
};

export default useCreateSensor;

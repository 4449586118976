import { useState } from 'react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import useUserStore from '../store/userStore';
import { msalConfig } from '../auth/authConfig';

const ERROR_TEXT = "The user doesn't have access to the Sensor Inventory web page";

interface FetchData {
  token: string | null;
  email: string | null;
  updateUser: (data: any) => any;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
  navigate: (string) => void;
};

const fetchData = async ({
    token,
    email,
    updateUser,
    setError,
    setLoading,
    navigate,
  }: FetchData) => {
  try {
    setLoading(true);
    const response = await axios.get(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/User/${email}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    const user = response.data;
    if (!user) {
      NotificationManager.error(ERROR_TEXT);
      return navigate('/sign-in');
    };
    updateUser(user);
  } catch (error) {
    setError(error);
    NotificationManager.error(ERROR_TEXT);
    return navigate('/sign-in');
  } finally {
    setLoading(false);
  }
};

interface FetchUserData {
  fetchUser: () => void;
  loading: boolean;
  error: Error | null;
};

const useFetchUser = (): FetchUserData => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const { updateUser, updateLoading } = useUserStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const onLoadingSet = (newLoadingStatus: boolean) => {
    setLoading(newLoadingStatus);
    updateLoading(newLoadingStatus);
  }

  const fetchUser = () => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };
    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => {
        if (!response.account) return navigate('/sign-in');
        fetchData({
          token: response.accessToken,
          email: response.account.username,
          updateUser,
          setError,
          setLoading: onLoadingSet,
          navigate,
        })
      });
    } else {
      navigate('/sign-in');
    }
  }

  return { fetchUser, loading, error };
};

export default useFetchUser;

import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { useMsal } from '@azure/msal-react';
import useFetchSensorStatuses from './useFetchSensorStatuses';
import { msalConfig } from '../auth/authConfig';
import { errorHandler } from '../utils/helpers';
import useUserStore from '../store/userStore';

interface EditSensorStatusRequest {
  id: string | number;
  token: string | null;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
  fetchSensorsStatuses: () => void;
  data: EditStatusDTO;
};

const editSensorStatusRequest = async ({
    token,
    setError,
    setLoading,
    fetchSensorsStatuses,
    data,
    id,
  }: EditSensorStatusRequest) => {
  try {
    const response = await axios.put(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Sensor/Status/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    if (response.status === 200) {
      NotificationManager.success('Sensor Status is successfully edited');
    }
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    fetchSensorsStatuses();
    setLoading(false);
  }
};

interface EditStatusDTO { description: string, userId?: string | number };

interface EditSensorStatus {
  editSensorStatus: (id: string | number, formData: EditStatusDTO) => void;
  loading: boolean;
  error: Error | null;
};

const useEditSensorStatus = (): EditSensorStatus => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { fetchSensorsStatuses } = useFetchSensorStatuses();
  
  const { user } = useUserStore();

  const editSensorStatus = (id, formData: EditStatusDTO) => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };

    if (!user) return navigate('/sign-in');

    const data: EditStatusDTO = {
      ...formData,
      userId: user.id,
    };

    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => editSensorStatusRequest({
        id,
        token: response.accessToken,
        setError,
        fetchSensorsStatuses,
        setLoading,
        data,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { editSensorStatus, loading, error };
};

export default useEditSensorStatus;

import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { useMsal } from '@azure/msal-react';
import useFetchClients from './useFetchClients';
import { msalConfig } from '../auth/authConfig';
import { errorHandler } from '../utils/helpers';
import useUserStore from '../store/userStore';

interface CreateSiteRequest {
  token: string | null;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
  fetchClients: () => any;
  data: CreateSiteDTO;
};

const createSiteRequest = async ({
    token,
    setError,
    setLoading,
    fetchClients,
    data,
  }: CreateSiteRequest) => {
  try {
    const response = await axios.post(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Site`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    if (response.status === 200) {
      NotificationManager.success('Site is successfully created');
    }
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    fetchClients();
    setLoading(false);
  }
};

interface CreateSiteDTO { name: string, siteId: string, clientId: number, userId?: string | number };

interface CreateSite {
  createSite: (formData: CreateSiteDTO) => void;
  loading: boolean;
  error: Error | null;
};

const useCreateSite = (): CreateSite => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { fetchClients } = useFetchClients();
  
  const { user } = useUserStore();

  const createSite = (formData: CreateSiteDTO) => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };

    if (!user) return navigate('/sign-in');

    const data: CreateSiteDTO = {
      ...formData,
      userId: user.id,
    };

    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => createSiteRequest({
        token: response.accessToken,
        setError,
        fetchClients,
        setLoading,
        data,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { createSite, loading, error };
};

export default useCreateSite;

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import useUserStore from '../store/userStore';
import theme from '../theme';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const NavigatorList = ({ items, open, actionItems, sideBarActions, handleDrawerClose, isMobile }) => (
  <>
    <List>
      {items.map(({ text, link, icon }) => (
        <Link key={link}  style={{ textDecoration: 'none', color: 'inherit' }} to={link}>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={isMobile && handleDrawerClose}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </Link>
      ))}
    </List>
    <Divider />
    <List>
      {actionItems.map(({ text, action, icon }) => (
        <ListItem key={action} onClick={sideBarActions[action]} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
            onClick={isMobile && handleDrawerClose}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  </>
);

interface ISideBar {
  open: boolean;
  handleDrawerClose: () => void;
  items: { text: string, link: string, icon: React.ReactElement }[];
  actionItems: { text: string, icon: React.ReactElement, action: string }[];
};

const SideBar = ({ open, handleDrawerClose, items, actionItems }: ISideBar) => {
  const isMobile = useMediaQuery('(max-width:500px)');
  const { updateUser } = useUserStore();

  const msalContext = useMsal();

  const sideBarActions = useMemo(() => ({
    logout: () => {
      msalContext.instance.logout();
      updateUser(null);
    },
  }), []);

  return isMobile ? (
    <SwipeableDrawer
      anchor={'left'}
      open={open}
      onClose={handleDrawerClose}
      onOpen={() => {}}
    >
      <Box
        sx={{ width: drawerWidth }}
        role="presentation"
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
            mt: 1,
          }}
          onClick={handleDrawerClose}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <CloseIcon />
          </ListItemIcon>
          <ListItemText primary={"Close"} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
        <Divider />
        <NavigatorList
          items={items}
          open={open}
          actionItems={actionItems}
          sideBarActions={sideBarActions}
          handleDrawerClose={handleDrawerClose}
          isMobile
        />
      </Box>
    </SwipeableDrawer>
  ) : (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <NavigatorList items={items} open={open} actionItems={actionItems} sideBarActions={sideBarActions} />
    </Drawer>
  );
}

export default SideBar;
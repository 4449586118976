import { useState } from 'react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import useClientStore from '../store/clientStore';
import { msalConfig } from '../auth/authConfig';
import { errorHandler } from '../utils/helpers';
import { Client, Site } from '../definitions/client';

interface FetchData {
  token: string | null;
  updateClients: (data: Client[]) => void;
  updateAllSites: (data: Site[]) => void;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
};

const fetchData = async ({
    token,
    updateClients,
    updateAllSites,
    setError,
    setLoading,
  }: FetchData) => {
  try {
    const response = await axios.get(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Client/Sites`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    const clients: Client[] = response.data;
    const sites = clients.reduce((acc: Site[], curr: Client) => {
      const sites = curr.sites.map(site => ({
        clientName: curr.name,
        ...site,
      }));
      acc = [...acc, ...sites];
      return acc;
    }, []);
    updateClients(clients);
    updateAllSites(sites);
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    setLoading(false);
  }
};

interface FetchClients {
  fetchClients: () => void;
  loading: boolean;
  error: Error | null;
};

const useFetchClients = (): FetchClients => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const { updateClients, updateAllSites } = useClientStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchClients = () => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };
    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => fetchData({
        token: response.accessToken,
        updateAllSites,
        updateClients,
        setError,
        setLoading,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { fetchClients, loading, error };
};

export default useFetchClients;

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const LoaderWrap = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  padding: 20,
}));

const PageLoader = () => (
  <LoaderWrap>
    <CircularProgress />
  </LoaderWrap>
)

export default PageLoader;

import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { useMsal } from '@azure/msal-react';
import useFetchSensorModeles from './useFetchSensorModels';
import { msalConfig } from '../auth/authConfig';
import useUserStore from '../store/userStore';
import { errorHandler } from '../utils/helpers';

interface EditSensorModelRequest {
  id: string | number;
  token: string | null;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
  fetchSensorsModels: () => void;
  data: EditModelDTO;
};

const editSensorModelRequest = async ({
    token,
    setError,
    setLoading,
    fetchSensorsModels,
    data,
    id,
  }: EditSensorModelRequest) => {
  try {
    const response = await axios.put(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Sensor/Models/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    if (response.status === 200) {
      NotificationManager.success('Sensor Model is successfully edited');
    }
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    fetchSensorsModels();
    setLoading(false);
  }
};

interface EditModelDTO { description: string, userId?: string | number };

interface EditSensorModel {
  editSensorModel: (id: string | number, formData: EditModelDTO) => void;
  loading: boolean;
  error: Error | null;
};

const useEditSensorModel = (): EditSensorModel => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { fetchSensorsModels } = useFetchSensorModeles();
  
  const { user } = useUserStore();

  const editSensorModel = (id, formData: EditModelDTO) => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };

    if (!user) return navigate('/sign-in');

    const data: EditModelDTO = {
      ...formData,
      userId: user.id,
    };

    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => editSensorModelRequest({
        id,
        token: response.accessToken,
        setError,
        fetchSensorsModels,
        setLoading,
        data,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { editSensorModel, loading, error };
};

export default useEditSensorModel;

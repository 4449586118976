import { create } from 'zustand';
import { Vendor, Contact } from '../definitions/vendor';

interface VendorStore {
  vendors: Vendor[];
  updateVendors: (vendors: Vendor[]) => void;

  allVendorsContacts: Contact[],
  updateAllVendorsContacts: (allVendorsContacts: Contact[]) => void,

  loading: boolean;
  updateLoading: (loading: boolean) => void;
}

const useVendorStore = create<VendorStore>()((set) => ({
  vendors: [],
  updateVendors: (vendors) => set({ vendors }),

  allVendorsContacts: [],
  updateAllVendorsContacts: (allVendorsContacts) => set({ allVendorsContacts }),

  loading: false,
  updateLoading: (loading) => set({ loading }),
}));

export default useVendorStore;
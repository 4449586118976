import React, { useEffect, useCallback, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useForm, Controller } from "react-hook-form";
import once from 'lodash.once';

import * as FormCommon from '../formCommon';

import useSensorStore from '../../store/sensorStore';
import useVendorStore from '../../store/vendorStore';
import useClientStore from '../../store/clientStore';

import useFetchVendors from '../../hooks/useFetchVendors';
import useFetchSensorStatuses from '../../hooks/useFetchSensorStatuses';
import useFetchSensorUseCases from '../../hooks/useFetchSensorUseCases';
import useFetchSensorModels from '../../hooks/useFetchSensorModels';
import useFetchSensorPurchaseOrders from '../../hooks/useFetchSensorPurchaseOrders';
import useFetchClients from '../../hooks/useFetchClients';
import { SensorFormData } from '../../definitions/sensor';

import {
  STATUSES_WITH_CUSTOMER_NEEDED,
  STATUSES_WITH_CUSTOMER_NOT_MANDATORY,
  MOBILE_MEDIA_QUERY_WIDTH,
} from '../../utils/constants';
import {
  FORM_DEFAULT_VALUES,
} from './constants';
import DatePicker from '../DatePicker';
import PageLoader from '../PageLoader';
import Select from '../Select';
import CommentsForm from '../CommentsForm';
import Textarea from '../Textarea';

import { objectCleaner } from '../../utils/helpers';

import {
  prepareVendorSelect,
  prepareVendorContactsSelect,
  preparePurchaseOrdersSelect,
  prepareClientsSelect,
  prepareSiteSelect,
} from './helpers';

interface ISensorForm {
  editData?: SensorFormData;
  onSubmitRequest: (data: SensorFormData) => void;
  submitLoading: boolean;
  mostRecentComment?: string;
}

export default function SensorForm({
  onSubmitRequest,
  submitLoading,
  editData,
  mostRecentComment,
}: ISensorForm) {
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY_WIDTH);
  const [screenLoading, setScreenLoading] = useState(false);
  const { fetchSensorsStatuses } = useFetchSensorStatuses();
  const { fetchSensorsUseCases } = useFetchSensorUseCases();
  const { fetchSensorsModels } = useFetchSensorModels();
  const { fetchSensorPurchaseOrders } = useFetchSensorPurchaseOrders();
  const { fetchClients } = useFetchClients();
  const { fetchVendors } = useFetchVendors();

  const { sensorStatuses, sensorUseCases, sensorModels, sensorPurchaseOrders } = useSensorStore();
  const { vendors } = useVendorStore();
  const { clients } = useClientStore();

  const isEdit = !!editData;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({ defaultValues: isEdit ? editData : FORM_DEFAULT_VALUES });

  const sensorModelId = watch('sensorModelId');

  const currentModel = useMemo(() => sensorModels.find((({ id }) => id === sensorModelId)), [sensorModelId]);

  const partDescription = currentModel?.partDescription;

  const vendorId = watch('vendorId');

  const currentVendor = useMemo(() => vendors.find((({ id }) => id === vendorId)), [vendorId]);

  const vendorContacts = currentVendor?.contacts || [];

  const clientId = watch('clientId');

  const currentClient = clients.find((({ id }) => id === clientId));

  const currentSiteId = watch('siteId');

  const sites = currentClient?.sites || [];

  const statusId = watch('statusId');

  const currentStatusName = useMemo(() => sensorStatuses.find((({ id }) => id === statusId))?.description, [sensorStatuses, statusId]);

  const [dateErrors, setDateErrors] = useState<{ installDate: boolean, receivedDate: boolean }>({
    installDate: false,
    receivedDate: false,
  });

  const onSubmit = (data: SensorFormData) => {
    const receivedDateError = !data.receivedDate;
    const installDateError = currentStatusName === "Installed" && !data.installDate;
    if (receivedDateError) {
      setDateErrors({ ...dateErrors, receivedDate: true });
    }
    if (installDateError) {
      setDateErrors({ ...dateErrors, installDate: true });
    }
    if (receivedDateError || installDateError) return;
    const reqData = objectCleaner(data);
    onSubmitRequest(reqData as SensorFormData);
  };
  
  const loadData = useCallback(once(() => {
    setScreenLoading(true);
    Promise.allSettled([
      fetchSensorsStatuses(),
      fetchSensorsUseCases(),
      fetchSensorsModels(),
      fetchVendors(),
      fetchSensorPurchaseOrders(),
      fetchClients(),
    ]).finally(() => setScreenLoading(false))
  }), []);

  useEffect(() => {
    if (!currentStatusName) return;
    if (![...STATUSES_WITH_CUSTOMER_NEEDED, ...STATUSES_WITH_CUSTOMER_NOT_MANDATORY].includes(currentStatusName)) {
      setValue('clientId', clients.find(({ isClient }) => !isClient)?.id)
    }
  }, [currentStatusName]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    mostRecentComment && setValue('comment', mostRecentComment)
  }, [mostRecentComment]);

  const onStatusChange = () => {
    setValue('siteId', null);
    setValue('clientId', null);
  };

  const onCustomerChange = () => {
    setValue('siteId', null);
  };

  const isCustomerRequired = useMemo(() => {
    if (!currentStatusName) return;
    return STATUSES_WITH_CUSTOMER_NEEDED.includes(currentStatusName);
  }, [statusId]);

  const showCustomerField = useMemo(() => {
    if (isCustomerRequired) return true;
    if (!currentStatusName) return;
    return STATUSES_WITH_CUSTOMER_NOT_MANDATORY.includes(currentStatusName);
  }, [statusId]);

  const showSensorLocationField = currentStatusName && currentStatusName !== 'Sold';

  const statusIsRMA = currentStatusName === 'RMA';

  const formBlockWidth = isMobile ? 350 : 400;

  const commentFieldWidth = isMobile && 280;

  return (screenLoading || submitLoading) ? (
    <PageLoader />
  ) : (
    <FormCommon.Form onSubmit={handleSubmit(onSubmit)}>
      <FormCommon.FormBlockTitle>Sensor Data</FormCommon.FormBlockTitle>
      <FormCommon.FormBlockWrapper sx={{ width: formBlockWidth }}>
        <Controller
          name="sensorId"
          control={control}
          rules={{ required: 'Sensor ID is required' }}
          render={({ field }) => (
            <FormCommon.StyledTextField
              inputProps={{ maxLength: 30, pattern: '[A-Za-z0-9 ]*' }}
              error={!!errors?.sensorId}
              variant="outlined"
              label='Sensor ID'
              required
              disabled={!!editData}
              {...field}
            />
          )}
        />
        <FormCommon.SelectWrap>
          <Select
            labelId="status-label"
            id="statusId"
            label="Status"
            options={sensorStatuses}
            onChange={onStatusChange}
            value={statusId}
            register={register}
            error={!!errors?.statusId}
            required
          />
        </FormCommon.SelectWrap>
        <FormCommon.SelectWrap>
          <Select
            labelId="sensorModelId-label"
            id="sensorModelId"
            label="Model Name"
            options={sensorModels}
            value={sensorModelId}
            register={register}
            required
            error={!!errors?.sensorModelId}
          />
          {partDescription && (
            <>
              <FormCommon.PartDescriptionTitle>(Model Name) Partial Description</FormCommon.PartDescriptionTitle>
              <FormCommon.PartDescription>{partDescription}</FormCommon.PartDescription>
            </>
          )}
        </FormCommon.SelectWrap>
        {showCustomerField && (
          <FormCommon.SelectWrap>
            <Select
              labelId="clientId-label"
              id="clientId"
              label="Customer"
              onChange={onCustomerChange}
              options={prepareClientsSelect(clients, isCustomerRequired)}
              value={clientId}
              register={register}
              error={!!errors?.clientId}
              required
            />
          </FormCommon.SelectWrap>
        )}
        {showSensorLocationField && (
          <FormCommon.SelectWrap>
            <Select
              labelId="siteId-label"
              id="siteId"
              label="Sensor Location"
              options={prepareSiteSelect(sites)}
              value={currentSiteId}
              register={register}
              error={!!errors?.siteId}
              required={!!clientId}
            />
          </FormCommon.SelectWrap>
        )}
        <FormCommon.SelectWrap>
          <Select
            labelId="useCaseId-label"
            id="useCaseId"
            label="Use Case"
            options={sensorUseCases}
            value={watch('useCaseId')}
            register={register}
            required={currentStatusName === "Installed"}
            error={!!errors?.useCaseId}
          />
        </FormCommon.SelectWrap>
      </FormCommon.FormBlockWrapper>
      <FormCommon.FormBlockTitle>Vendor Data</FormCommon.FormBlockTitle>
      <FormCommon.FormBlockWrapper sx={{ width: formBlockWidth }}>
        <FormCommon.SelectWrap>
          <Select
            labelId="vendorId-label"
            id="vendorId"
            label="Vendor"
            options={prepareVendorSelect(vendors)}
            value={watch('vendorId')}
            register={register}
            required
            error={!!errors?.vendorId}
          />
        </FormCommon.SelectWrap>
        {(!vendorContacts.length && vendorId) ? (
          <FormCommon.PartDescription
            style={{ marginBottom: 15, marginTop: 5 }}
          >
            There are no contacts for this vendor
          </FormCommon.PartDescription>
        ) : null}
        {vendorContacts.length ? (
          <FormCommon.SelectWrap>
            <Select
              labelId="vendorContactId-label"
              id="vendorContactId"
              label="Vendor Contact"
              options={prepareVendorContactsSelect(vendorContacts)}
              value={watch('vendorContactId')}
              register={register}
              error={!!errors?.vendorContactId}
            />
          </FormCommon.SelectWrap>
        ) : null}
        <FormCommon.StyledTextField
          id="leadTime"
          label="Lead Time"
          variant="outlined"
          {...register("leadTime", { required: false })}
          error={!!errors?.leadTime}
        />
      </FormCommon.FormBlockWrapper>
      <FormCommon.FormBlockTitle>Additional Data</FormCommon.FormBlockTitle>
      <FormCommon.FormBlockWrapper sx={{ width: formBlockWidth }}>
        <FormCommon.DatePickerWrap>
          <DatePicker
            defaultValue={editData?.receivedDate}
            error={dateErrors.receivedDate}
            setValue={setValue}
            id="receivedDate"
            required
            label="Received Date"
          />
        </FormCommon.DatePickerWrap>
        <FormCommon.StyledTextField
          id="outlined-basic"
          label="Cost"
          variant="outlined"
          type="number"
          required
          {...register("cost", { required: true })}
          error={!!errors?.cost}
        />
        <FormCommon.SelectWrap>
          <Select
            labelId="purchaseOrderId-label"
            id="purchaseOrderId"
            label="PO Number"
            options={preparePurchaseOrdersSelect(sensorPurchaseOrders)}
            value={watch('purchaseOrderId')}
            register={register}
            required
            error={!!errors?.purchaseOrderId}
          />
        </FormCommon.SelectWrap>
        <FormCommon.DatePickerWrap>
          <DatePicker
            setValue={setValue}
            id="installDate"
            error={dateErrors.installDate}
            label="Install Date"
            required={currentStatusName === 'Installed'}
            defaultValue={editData?.installDate}
          />
        </FormCommon.DatePickerWrap>
        {editData?.warrantyExpiration ? (
          <FormCommon.DatePickerWrap>
            <DatePicker
              defaultValue={editData.warrantyExpiration}
              setValue={setValue}
              id="warrantyExpiration"
              label="Warranty Expiration Date"
              disabled
            />
          </FormCommon.DatePickerWrap>
        ) : currentVendor ? (
          <div style={{ marginBottom: 15 }}>
            <FormCommon.PartDescriptionTitle>Warranty Expiration Date</FormCommon.PartDescriptionTitle>
            <FormCommon.PartDescription>
              {currentVendor?.name === 'LevelCon' ? '1 year from installation date' : '1 year from received date'}
            </FormCommon.PartDescription>
          </div>
        ) : null}
        {currentStatusName === 'Written Off' && (
          <FormCommon.DatePickerWrap>
            <DatePicker
              setValue={setValue}
              id="writtenOffDate"
              label="Written Off Date"
              defaultValue={editData?.writtenOffDate}
            />
          </FormCommon.DatePickerWrap>
        )}
        {statusIsRMA && (
          <FormCommon.StyledTextField
            id="outlined-basic"
            label="Replacement"
            variant="outlined"
            {...register("replacement", { required: false })}
            error={!!errors?.replacement}
          />
        )}
        {statusIsRMA && (
          <FormCommon.DatePickerWrap>
            <DatePicker
              setValue={setValue}
              id="receivedReplacement"
              label="Received Replacement Date"
              defaultValue={editData?.receivedReplacement}
            />
          </FormCommon.DatePickerWrap>
        )}
        {statusIsRMA && (
          <FormCommon.DatePickerWrap>
            <DatePicker
              setValue={setValue}
              id="shippedDate"
              label="Shipped Date"
              defaultValue={editData?.shippedDate}
            />
          </FormCommon.DatePickerWrap>
        )}
        {statusIsRMA && (
          <FormCommon.StyledTextField
            id="outlined-basic"
            label="RMA Number"
            variant="outlined"
            type="number"
            {...register("rmaNumber", { required: false })}
            error={!!errors?.rmaNumber}
          />
        )}
        {isEdit ? <div>Most Recent Comment</div> : <div>Comment</div>}
        {isEdit ? (
          <div style={{ position: 'relative', width: commentFieldWidth || '100%' }}>
            {mostRecentComment ? (
              <Textarea
                aria-label="minimum height"
                placeholder="Most Recent Comment"
                disabled
                {...register("comment", { required: false })}
              />
            ) : (
              <FormCommon.PartDescription
                style={{ marginBottom: 15, marginTop: 5 }}
              >
                No comments yet.
              </FormCommon.PartDescription>
            )}
            <CommentsForm />
          </div>
        ) : (
          <Textarea
            aria-label="minimum height"
            placeholder="Add comment here"
            {...register("comment", { required: false })}
          />
        )}
      </FormCommon.FormBlockWrapper>
      <FormCommon.SubmitBtn sx={{ width: formBlockWidth }} type="submit">Submit</FormCommon.SubmitBtn>
    </FormCommon.Form>
  );
}

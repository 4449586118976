import React from 'react';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { InstallationHistory } from '../definitions/sensor';
import { IPAD_MEDIA_QUERY_WIDTH, MOBILE_MEDIA_QUERY_WIDTH } from '../utils/constants';
import { formatDateToCDT } from '../utils/helpers';
import theme from '../theme';

const columns = [
  {
    field: 'action',
    headerName: 'Action',
    width: 150,
    sortable: false,
    filterable: false,
    resizable: false,
  },
  {
    field: 'actionDate',
    headerName: 'Action Date',
    width: 200,
    valueGetter: (value) => formatDateToCDT(value),
    sortable: false,
    filterable: false,
    resizable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    sortable: false,
    filterable: false,
    resizable: false,
  },
  {
    field: 'siteName',
    headerName: 'Location',
    width: 150,
    sortable: false,
    filterable: false,
    resizable: false,
  },
  {
    field: 'clientName',
    headerName: 'Customer',
    width: 150,
    valueGetter: (value) => value === 'No Client' ? '' : value,
    sortable: false,
    filterable: false,
    resizable: false,
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 150,
    sortable: false,
    filterable: false,
    resizable: false,
  },
];

const Title = styled('div')({
  display: 'flex',
  marginBottom: 10,
  fontSize: 24,
  justifyContent: 'center',
});

const InstallationsWrap = styled('div')({
  padding: 15,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'column-reverse',
});

export default function InstallationHistoryTable({
  installationHistory
}: {
  installationHistory: InstallationHistory[],
}) {
  const isIpad = useMediaQuery(IPAD_MEDIA_QUERY_WIDTH);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY_WIDTH);

  const installations = installationHistory.reduce((acc: InstallationHistory[][], curr: InstallationHistory, currIndex) => {
    if (curr.action === 'Installed') {
      const installation: InstallationHistory[] = [];
      installation.push(curr);
      const removedInstallation = installationHistory[currIndex + 1];
      removedInstallation && installation.push(removedInstallation);
      acc.push(installation);
    };
    return acc;
  }, []).reverse();

  const boxWidth = isMobile ? 350 : (isIpad ? 700 : '100%');

  return (
    <div>
      <Title>Installation History</Title>
      <InstallationsWrap style={{ width: boxWidth }}>
        {installations.map((installation: InstallationHistory[], index: number) => (
          <div key={installation[0].id} style={{ marginBottom: 10 }}>
            <Title>Installation #{index + 1}</Title>
            <DataGrid
              rows={installation}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              getRowId={(row: InstallationHistory) => row.id}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              hideFooterPagination
              hideFooterSelectedRowCount

            />
          </div>
        ))}
      </InstallationsWrap>
    </div>
  )
};

import { create } from 'zustand';

import { GridRowModesModel } from '@mui/x-data-grid';

interface DashboardContextStore {
  quickEditSensorId?: string | number;
  setQuickEditSensorId: (quickEditSensorId?: string | number) => void;

  rowModesModel: GridRowModesModel,
  setRowModesModel: (rowModesModel: any) => void;

  quickEditState: any;
  setQuickEditState: (quickEditState: any) => void;
}

const useDashboardContextStore = create<DashboardContextStore>()((set) => ({
  quickEditSensorId: '',
  setQuickEditSensorId: (quickEditSensorId) => set({ quickEditSensorId }),

  rowModesModel: {},
  setRowModesModel: (rowModesModel) => set({ rowModesModel }),

  quickEditState: {},
  setQuickEditState: (quickEditState) => set({ quickEditState }),
}));

export default useDashboardContextStore;
import { Vendor, Contact } from '../../definitions/vendor';
import { PurchaseOrder } from '../../definitions/sensor';
import { Client, Site } from '../../definitions/client';

export const prepareVendorSelect = (vendors: Vendor[]) => vendors.map(({ name, id }) => ({ id , description: name}));

export const prepareVendorContactsSelect = (vendorsContacts: Contact[]) => vendorsContacts.map(({ name, id }) => ({ id , description: name}));

export const preparePurchaseOrdersSelect = (purchaseOrders: PurchaseOrder[]) => purchaseOrders.map(({ number, id }) => ({ id , description: number}));

export const prepareClientsSelect =
  (purchaseOrders: Client[], isCustomerRequired?: boolean) =>
    isCustomerRequired ? 
      purchaseOrders
        .filter(({ isClient }) => isClient).map(({ name, id }) => ({ id , description: name}))
      : purchaseOrders
          .map(({ name, id, isClient }) => {
            if (isClient) {
              return ({ id, description: name});
            }
            return ({ id, description: 'None' })
          });

export const prepareSiteSelect = (sites: Site[]) => sites.map(({ name, id }) => ({ id , description: name}));

import * as React from 'react';
import moment, { Moment } from 'moment';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker as Input } from '@mui/x-date-pickers/DatePicker';

const StyledInput= styled(Input)({
  width: '100%',
});

interface IDatePicker {
  label?: string;
  id: string;
  setValue: any;
  error?: boolean;
  defaultValue?: string | null;
  disabled?: boolean;
  required?: boolean;
};

const DatePicker = ({ label = '', required = false, id, setValue, error = false, defaultValue, disabled = false}: IDatePicker) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StyledInput
        defaultValue={defaultValue ? moment(defaultValue) : null}
        name={id}
        disabled={disabled}
        label={`${label} ${required ? '*' : ''}`}
        sx={disabled ? {} : {
          // Apply red border if there's an error
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: error ? 'red' : 'grey',
            },
            '&:hover fieldset': {
              borderColor: error ? 'red' : 'blue',
            },
          },
        }}
        onChange={(value: Moment | null) => {
          setValue(id, moment.utc(value))
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;

import { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { useMsal } from '@azure/msal-react';
import useFetchSensorCommentHistory  from './useFetchSensorCommentHistory';
import { msalConfig } from '../auth/authConfig';
import { CommentDTO } from '../definitions/sensor';
import { errorHandler } from '../utils/helpers';
import useUserStore from '../store/userStore';


interface AddSensorCommentRequest {
  token: string | null;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
  navigate: (string) => void,
  fetchSensorCommentHistory: () => void,
  data: CommentDTO;
};

const addSensorCommentRequest = async ({
    token,
    fetchSensorCommentHistory,
    setError,
    setLoading,
    navigate,
    data,
  }: AddSensorCommentRequest) => {
  setLoading(true);
  try {
    const response = await axios.post(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Sensor/Comment`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    if (response.status === 200) {
      NotificationManager.success('Comment is added to the sensor');
      fetchSensorCommentHistory();
      return
    }
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    setLoading(false);
  }
};

interface AddSensorComment {
  addSensorComment: (comment: string) => void;
  loading: boolean;
  error: Error | null;
};

const useAddSensorComment = (): AddSensorComment => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { fetchSensorCommentHistory } = useFetchSensorCommentHistory();
  
  const { user } = useUserStore();

  const addSensorComment = (comment) => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };

    if (!user) return navigate('/sign-in');

    if (!id) return;

    const data: CommentDTO = {
      comment,
      sensorId: Number(id),
      userId: user.id,
    };

    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => addSensorCommentRequest({
        token: response.accessToken,
        fetchSensorCommentHistory,
        setError,
        setLoading,
        navigate,
        data,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { addSensorComment, loading, error };
};

export default useAddSensorComment;

import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { useMsal } from '@azure/msal-react';
import useFetchSensorStatuses from './useFetchSensorStatuses';
import { msalConfig } from '../auth/authConfig';
import { errorHandler } from '../utils/helpers';
import useUserStore from '../store/userStore';

interface CreateSensorStatusRequest {
  token: string | null;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
  fetchSensorsStatuses: () => any;
  data: CreateStatusDTO;
};

const createSensorStatusRequest = async ({
    token,
    setError,
    setLoading,
    fetchSensorsStatuses,
    data,
  }: CreateSensorStatusRequest) => {
  try {
    const response = await axios.post(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Sensor/Status`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    if (response.status === 200) {
      NotificationManager.success('Sensor Status is successfully created');
    }
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    fetchSensorsStatuses();
    setLoading(false);
  }
};

interface CreateStatusDTO { description: string, userId?: string | number };

interface CreateSensorStatus {
  createSensorStatus: (formData: CreateStatusDTO) => void;
  loading: boolean;
  error: Error | null;
};

const useCreateSensorStatus = (): CreateSensorStatus => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { fetchSensorsStatuses } = useFetchSensorStatuses();
  
  const { user } = useUserStore();

  const createSensorStatus = (formData: CreateStatusDTO) => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };

    if (!user) return navigate('/sign-in');

    const data: CreateStatusDTO = {
      ...formData,
      userId: user.id,
    };

    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => createSensorStatusRequest({
        token: response.accessToken,
        setError,
        fetchSensorsStatuses,
        setLoading,
        data,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { createSensorStatus, loading, error };
};

export default useCreateSensorStatus;

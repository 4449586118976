import { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { useMsal } from '@azure/msal-react';
import useFetchSensors from './useFetchSensors';
import { msalConfig } from '../auth/authConfig';
import { SensorFormData, SensorDTO } from '../definitions/sensor';
import { errorHandler } from '../utils/helpers';
import useUserStore from '../store/userStore';

const prepareData = (data: SensorDTO): SensorDTO => ({
  ...data,
  cost: Number(data.cost) || 0,
});

interface EditSensorRequest {
  id?: string | number,
  isQuickEdit: boolean,
  token: string | null;
  setError: (data: any) => any;
  setLoading: (data: any) => any;
  navigate: (string) => void,
  fetchSensors: () => void,
  data: SensorDTO;
};

const editSensorRequest = async ({
    id,
    isQuickEdit,
    fetchSensors,
    token,
    setError,
    setLoading,
    navigate,
    data,
  }: EditSensorRequest) => {
  if (!id) return;
  setLoading(true);
  try {
    const response = await axios.put(`${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Sensor/${id}`, prepareData(data), {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
      },
    });
    if (response.status === 200) {
      NotificationManager.success('Sensor Edited');
      return navigate('/');
    }
  } catch (error) {
    errorHandler(error, setError);
  } finally {
    setLoading(false);
    if (isQuickEdit) {
      return fetchSensors();
    };
  }
};

interface EditSensor {
  editSensor: (formData: SensorFormData | any, quickId?: string | number) => void;
  loading: boolean;
  error: Error | null;
};

const useEditSensor = (): EditSensor => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { fetchSensors } = useFetchSensors();
  
  const { user } = useUserStore();

  const editSensor = (formData, quickId?: string | number) => {
    if (accounts.length > 0) {
    const accessTokenRequest = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: accounts[0],
    };

    if (!user) return navigate('/sign-in');
    if (!quickId && !id) return navigate('/');

    const data: SensorDTO = {
      ...formData,
      userId: user.id,
    };

    instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => editSensorRequest({
        id: quickId || id,
        isQuickEdit: !!quickId,
        token: response.accessToken,
        fetchSensors,
        setError,
        setLoading,
        navigate,
        data,
      }));
    } else {
      navigate('/sign-in');
    }
  }

  return { editSensor, loading, error };
};

export default useEditSensor;

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import theme from '../theme';

export const Form = styled('form')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const FormBlockWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 10,
});

export const StyledTextField = styled(TextField)({
  marginBottom: 10,
});

export const DatePickerWrap = styled('div')({
  marginBottom: 10,
  width: '100%',
});

export const SelectWrap = styled('div')({
  marginBottom: 10,
  width: '100%',
});

export const FormBlockTitle = styled('div')({
  display: 'flex',
  marginBottom: 10,
  fontSize: 24,
});

export const PartDescriptionTitle = styled('div')({
  color: '#808080',
});

export const PartDescription = styled('div')({
  display: 'flex',
  alignItems: 'center',
  minHeight: 40,
  width: '100%',
  padding: 10,
  backgroundColor: '#d3d3d3',
  borderRadius: 3,
});

export const SubmitBtn = styled(Button)(() => ({
  backgroundColor: theme.palette.primary.main,
  marginTop: 10,
  marginBottom: 20,
  height: 50,
  color: '#fff',
  '&:hover': {
    background: theme.palette.primary.dark,
 },
}));

export const LoaderWrap = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  padding: 20,
}));
